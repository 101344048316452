// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { useEffect, useState } from "react";
import {
	Paper,
	Card,
	CardContent,
	makeStyles,
	TextField,
	Switch,
	Grid,
	Typography,
	CircularProgress,
	Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import storesActions from "../../../../redux/actions/stores.actions";
// import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	error: {
		color: "#d32f2f",
	},
	errorInput: {
		border: "1px solid #d32f2f",
	},
	item: {
		width: "100%",
	},
	remove: {
		color: "#ffffff",
		"background-color": "#eb4d5c",
		"border-color": "#eb4d5c",
		marginRight: "5px",
	},
	progressSubmitting: {
		marginLeft: "15px",
	},
	deleteButton: {
		marginLeft: "20px",
	},
}));

export default function Index(props) {
	const [storeFormEdit, setStoreFormEdit] = useState({});
	const stores = useSelector((state) => state.stores);
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		control,
		setValue,
		errors,
		handleSubmit,
		formState,
		getValues
	} = useForm({ defaultValues: { 
		isDeliveryJusto: false, 
		storeId: '', 
		isPyaDelivery: false, 
		isUberDelivery: false, 
		isOnlyUberDelivery: false, 
		isOnlyCaptain: false, 
		isOnlyPyaDelivery: false,
		isOnlyDeliveryJusto: false
	}});
	const { isSubmitting } = formState;
	
	const handleSubmitForm = async (values) => {
		values.id = props.match.params.id;
		values.store = stores?.singleData?.description
		// console.log(object)
		const response = await dispatch(storesActions.editStore(values));
		if (response === 'SUCCESS') {
			history.push(`/stores-justo`);
		} else if (response === 'ERROR') {
			
		} 
		console.log(response);
	};

	const handleChangeOnlyUberDelivery = (e, onChange) => {
		onChange(e.target.checked);
		setValue("isDeliveryJusto", false);
		setValue("isPyaDelivery", false);
		setValue("isUberDelivery", false);
		setValue("isOnlyCaptain", false);
		setValue("isOnlyPyaDelivery", false);
		setValue("isOnlyDeliveryJusto", false);
	}
	
	const handleChangeOnlyCaptain = (e, onChange) => {
		onChange(e.target.checked);
		setValue("isDeliveryJusto", false);
		setValue("isPyaDelivery", false);
		setValue("isUberDelivery", false);
		setValue("isOnlyUberDelivery", false);
		setValue("isOnlyPyaDelivery", false);
		setValue("isOnlyDeliveryJusto", false);
	}

	const handleChangeOnlyPyaDelivery = (e, onChange) => {
		onChange(e.target.checked);
		setValue("isDeliveryJusto", false);
		setValue("isPyaDelivery", false);
		setValue("isUberDelivery", false);
		setValue("isOnlyUberDelivery", false);
		setValue("isOnlyCaptain", false);
		setValue("isOnlyDeliveryJusto", false);
	}

	const handleChangeOnlyDeliveryJusto = (e, onChange) => {
		onChange(e.target.checked);
		setValue("isDeliveryJusto", false);
		setValue("isPyaDelivery", false);
		setValue("isUberDelivery", false);
		setValue("isOnlyUberDelivery", false);
		setValue("isOnlyCaptain", false);
		setValue("isOnlyPyaDelivery", false);
	}

	const disableChecks = () => {
		return (getValues("isOnlyUberDelivery") || getValues("isOnlyCaptain") || getValues("isOnlyPyaDelivery") || getValues("isOnlyDeliveryJusto"))
	}

	const getData = async () => {
		if (props.match.path === "/stores-justo/edit/:id") {
		//   console.log(stores.melt_store);
		dispatch(storesActions.getStore({melt_store: props.match.params.id}));
		console.log(stores.singleData);
		setStoreFormEdit(stores.singleData);
		}
	}

	useEffect(() => {
		if (storeFormEdit) {
			setValue("storeId", storeFormEdit?.justo_store_id);
			setValue("isDeliveryJusto", !!storeFormEdit?.is_justo_delivery_active);
			setValue("isPyaDelivery", !!storeFormEdit?.is_pya_delivery);
			setValue("isUberDelivery", !!storeFormEdit?.is_uber_delivery);
			setValue("isOnlyUberDelivery", !!storeFormEdit?.is_only_uber_delivery);
			setValue("isOnlyCaptain", !!storeFormEdit?.is_only_captain);
			setValue("isOnlyPyaDelivery", !!storeFormEdit?.is_only_pya_delivery);
			setValue("isOnlyDeliveryJusto", !!storeFormEdit?.is_only_justo_delivery);
		}
		// eslint-disable-next-line 
	}, [storeFormEdit]);

	useEffect(() => {
		if (stores.singleData) {
			setStoreFormEdit(stores.singleData);
		}
	}, [stores.singleData]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line 
	  }, [props.match.params.id]);

	return (
		<Paper className={classes.root}>
			<Card>
				{/* <Backdrop open={stores.dialButton} style={{ zIndex: 2000 }} /> */}
				<form
					id="storeJustoForm"
					onSubmit={handleSubmit(handleSubmitForm)}
				/>
					<CardContent>
						<Grid container spacing={3}>
							<Grid item sm={12} className={classes.item}>
								<Typography variant="h3">
									{props.match.path ===
									"/stores-justo/edit/:id"
										? "Editar Sucursal"
										: "Crear Sucursal"}
								</Typography>
							</Grid>
							<Grid item sm={12}>
								<Controller
									control={control}
									label="StoreID"
									placeholder="Ingrese Store ID"
									name="storeId"
									rules={{ required: true }}
									as={<TextField style={{ width: "100%" }} />}
								/>
								{errors.storeId && (
									<p className={classes.error}>
										* El storeId es requerido
									</p>
								)}
							</Grid>
							<Grid item sm={4}>
								<label htmlFor="isDeliveryJusto">Justo Delivery</label>
								<Controller
									control={control}
									label="Habilitar"
									name="isDeliveryJusto"
									render={(
										{ onChange, onBlur, value, ref, ...props },
										{ invalid, isTouched, isDirty }
									  ) => (
										<Switch
										  onBlur={onBlur}
										  onChange={(e) => onChange(e.target.checked)}
										  checked={value}
										  inputRef={ref}
											disabled={disableChecks()}
										  label="Habilitar"
										  color={"primary"}
										  {...props}
										/>
									  )}
								/>
							</Grid>
							<Grid item sm={4}>
								<label htmlFor="isPyaDelivery">Pedidos Ya Delivery</label>
								<Controller
									control={control}
									label="Habilitar"
									name="isPyaDelivery"
									render={(
										{ onChange, onBlur, value, ref, ...props },
										{ invalid, isTouched, isDirty }
										) => (
										<Switch
											onBlur={onBlur}
											onChange={(e) => onChange(e.target.checked)}
											checked={value}
											inputRef={ref}
											disabled={disableChecks()}
											label="Habilitar"
											color={"primary"}
											{...props}
										/>
										)}
								/>
							</Grid>
							<Grid item sm={4}>
								<label htmlFor="isUberDelivery">Uber Delivery</label>
								<Controller
									control={control}
									label="Habilitar"
									name="isUberDelivery"
									render={(
										{ onChange, onBlur, value, ref, ...props },
										{ invalid, isTouched, isDirty }
										) => (
										<Switch
											onBlur={onBlur}
											onChange={(e) => onChange(e.target.checked)}
											checked={value}
											inputRef={ref}
											disabled={disableChecks()}
											label="Habilitar"
											color={"primary"}
											{...props}
										/>
										)}
								/>
							</Grid>
							<Grid item sm={4}>
								<label htmlFor="isOnlyDeliveryJusto">Exclusivo Justo Delivery</label>
								<Controller
									control={control}
									label="Habilitar"
									name="isOnlyDeliveryJusto"
									render={(
										{ onChange, onBlur, value, ref, ...props },
										{ invalid, isTouched, isDirty }
										) => (
										<Switch
											onBlur={onBlur}
											onChange={(e) =>  handleChangeOnlyDeliveryJusto(e, onChange)}
											checked={value}
											inputRef={ref}
											disabled={getValues("isOnlyUberDelivery") || getValues("isOnlyPyaDelivery") || getValues("isOnlyCaptain")}
											label="Habilitar"
											color={"primary"}
											{...props}
										/>
										)}
								/>
							</Grid>
							<Grid item sm={4}>
								<label htmlFor="isOnlyPyaDelivery">Exclusivo Pedidos Ya Delivery</label>
								<Controller
									control={control}
									label="Habilitar"
									name="isOnlyPyaDelivery"
									render={(
										{ onChange, onBlur, value, ref, ...props },
										{ invalid, isTouched, isDirty }
										) => (
										<Switch
											onBlur={onBlur}
											onChange={(e) =>  handleChangeOnlyPyaDelivery(e, onChange)}
											checked={value}
											inputRef={ref}
											disabled={getValues("isOnlyUberDelivery") || getValues("isOnlyCaptain") || getValues("isOnlyDeliveryJusto")}
											label="Habilitar"
											color={"primary"}
											{...props}
										/>
										)}
								/>
							</Grid>
							<Grid item sm={4}>
								<label htmlFor="isOnlyUberDelivery">Exclusivo Uber Delivery</label>
								<Controller
									control={control}
									label="Habilitar"
									name="isOnlyUberDelivery"
									render={(
										{ onChange, onBlur, value, ref, ...props },
										{ invalid, isTouched, isDirty }
										) => (
										<Switch
											onBlur={onBlur}
											onChange={(e) =>  handleChangeOnlyUberDelivery(e, onChange)}
											checked={value}
											inputRef={ref}
											disabled={getValues("isOnlyCaptain") || getValues("isOnlyPyaDelivery") || getValues("isOnlyDeliveryJusto")}
											label="Habilitar"
											color={"primary"}
											{...props}
										/>
										)}
								/>
							</Grid>
							<Grid item sm={4}>
								<label htmlFor="isOnlyCaptain">Exclusivo Captain</label>
								<Controller
									control={control}
									label="Habilitar"
									name="isOnlyCaptain"
									render={(
										{ onChange, onBlur, value, ref, ...props },
										{ invalid, isTouched, isDirty }
										) => (
										<Switch
											onBlur={onBlur}
											onChange={(e) =>  handleChangeOnlyCaptain(e, onChange)}
											checked={value}
											inputRef={ref}
											disabled={getValues("isOnlyUberDelivery") || getValues("isOnlyPyaDelivery") || getValues("isOnlyDeliveryJusto")}
											label="Habilitar"
											color={"primary"}
											{...props}
										/>
										)}
								/>
							</Grid>
							<Grid item sm={12}>
								{props.match.path ===
									"/stores-justo/edit/:id" && (
									<Button
										type="submit"
										disabled={isSubmitting}
										bezelStyle="solid"
										variant="contained"
										form="storeJustoForm"
										color="primary"
									>
										Editar
										{isSubmitting && (
											<CircularProgress
												className={
													classes.progressSubmitting
												}
												size={25}
											/>
										)}
									</Button>
								)}
								{props.match.path ===
									"/stores-justo/create" && (
									<Button
										type="submit"
										disabled={isSubmitting}
										bezelStyle="solid"
										form="alertForm"
									>
										Crear
										{isSubmitting && (
											<CircularProgress
												className={
													classes.progressSubmitting
												}
												size={25}
											/>
										)}
									</Button>
								)}
							</Grid>
						</Grid>
					</CardContent>
			</Card>
		</Paper>
	);
}
