import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../redux/actions/menu.actions";
import { useHistory } from "react-router-dom";

export default function Index() {
  const utils = useSelector((state) => state.utils);
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (value) => {
    let path = value.toLowerCase();
    history.push("/menu" + (path === "resume" ? "" : "/" + path));
    dispatch(menuActions.setPage(value));
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3} md={2}>
        <TextField
          variant="outlined"
          margin="dense"
          value={menu.query.restaurant}
          name="restaurant"
          label="Restaurant"
          fullWidth
          select
        >
          <MenuItem value="1">XXXX{process.env.REACT_APP_NAME_WEB}</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={3} md={2}>
        <Autocomplete
          fullWidth
          options={utils.stores}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.value === value.value}
          disableClearable
          value={
            utils.stores.length
              ? utils.stores.find((store) => store.value === menu.query.store)
              : { title: "Todas", value: "0" }
          }
          onChange={(_e, k) =>
            dispatch(menuActions.setQuery({ store: k.value }))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              label="Sucursal"
              variant="outlined"
              margin="dense"
            />
          )}
        />
      </Grid>
      <Grid item align="right" xs={6} md={8}>
        <ButtonGroup size="small" variant="text">
          <Button
            color={menu.page === "RESUME" ? "primary" : "secondary"}
            onClick={() => handleClick("RESUME")}
          >
            Resumen
          </Button>
          <Button
            color={menu.page === "MENU" ? "primary" : "secondary"}
            onClick={() => handleClick("MENU")}
          >
            Menús
          </Button>
          <Button
            color={menu.page === "CATEGORIES" ? "primary" : "secondary"}
            onClick={() => handleClick("CATEGORIES")}
          >
            Categorías
          </Button>
          <Button
            color={menu.page === "ARTICLES" ? "primary" : "secondary"}
            onClick={() => handleClick("ARTICLES")}
          >
            Artículos
          </Button>
          <Button
            color={menu.page === "MODIFIERS" ? "primary" : "secondary"}
            onClick={() => handleClick("MODIFIERS")}
          >
            Modificadores
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
