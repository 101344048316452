import React, { useEffect, useCallback } from "react";
import axios from "axios";
import "./App.css";
import lightTheme from "./themes/light";
import darkTheme from "./themes/dark";
import { MuiThemeProvider, CssBaseline, makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import momentUtils from "@date-io/moment";

import { useSelector, useDispatch } from "react-redux";
import Router from "./components/Router";
import Notifies from "./components/Notifies";
import NavBar from "./components/Navigation/AppBar";
import DrawerMenu from "./components/Navigation/Drawer";

import { SocketContextProvider } from "./context/Socket";
import utilsActions from "./redux/actions/utils.actions";
import authActions from "./redux/actions/auth.actions";
import "moment/locale/es-do";

moment.locale("es-do");

// Configuración de axios y revisión de token
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const token = window.localStorage.getItem("token");
if (token && token !== null) {
  axios.defaults.headers.common.authorization = token;
}
// primer

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function App() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);

  const start = useCallback(() => {
    dispatch(authActions.verifyToken());
    dispatch(authActions.getStore());
    dispatch(utilsActions.fetchStores());
  }, [dispatch]);

  useEffect(() => {
    if (auth.loged === "LOGED") {
      start();
    }
  }, [auth.loged, start]);

  return (
    <MuiThemeProvider theme={app.theme === "LIGHT" ? lightTheme : darkTheme}>
      <SocketContextProvider>
        <MuiPickersUtilsProvider utils={momentUtils}>
          <div className={classes.root}>
            <CssBaseline />
            {auth.loged === "LOGED" && <NavBar />}
            {auth.loged === "LOGED" && <DrawerMenu />}
            <main
              className={classes.content}
              style={{ padding: 20, paddingTop: 80 }}
            >
              <Router />
            </main>
          </div>
          <Notifies />
        </MuiPickersUtilsProvider>
      </SocketContextProvider>
    </MuiThemeProvider>
  );
}
