// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { Fragment, useEffect, useCallback } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../../../redux/actions/menu.actions";
import Table from "./Table";

export default function Index() {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);

  const fetchMenus = useCallback(() => {
    dispatch(menuActions.fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  return (
    <Fragment>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item xs={3}>
          <TextField
            value={menu.categories.search}
            onChange={(e) =>
              dispatch(menuActions.setCategoriesSearch(e.target.value))
            }
            variant="outlined"
            margin="dense"
            fullWidth
            label="Buscar"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => dispatch(menuActions.setCategoriesView("CREATE"))}
          >
            Nueva categoría
          </Button>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Table />
        </Grid>
      </Grid>
    </Fragment>
  );
}
