import Axios from "axios";
import notifiesActions from "./notifies.actions";
import appActions from "./app.actions";

const getItem = (search) => {
  return async (dispatch, getState) => {
    const menu = await getState().menu;
    const category = await menu.resume.data.find(
      (item) => item.name === search.menu
    );
    const item = category.articles.find((item) => item.name === search.item);
    dispatch({ type: "MENU_SET_VIEW_ITEM", payload: item });
  };
};

const setQuery = (query) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_QUERY", payload: query });
  };
};

const setPage = (page) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_PAGE", payload: page });
  };
};

const setResumeExpand = (expand) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_RESUME_EXPAND", payload: expand });
  };
};

const fetchMenus = () => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await Axios.get("http://localhost:3001/menus");
      dispatch({ type: "MENU_MENUS_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const createMenu = (menu) => {
  return async (dispatch) => {
    try {
      await Axios.post("http://localhost:3001/menus", menu);
      dispatch(notifiesActions.enqueueNotify("MENU_CREATE_MENU_SUCCESS"));
      dispatch(fetchMenus());
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("MENU_CREATE_MENU_FAIL"));
    }
  };
};

const setMenusSearch = (search) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_MENUS_SEARCH", payload: search });
  };
};

const setMenusView = (view) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_MENUS_VIEW", payload: view });
  };
};

const setMenusDeleteModal = (value) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_MENUS_DELETE_MODAL", payload: value });
  };
};

const setMenusCreateModal = (value) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_MENUS_CREATE_MODAL", payload: value });
  };
};

const fetchCategories = () => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await Axios.get("http://localhost:3001/categories");
      dispatch({ type: "MENU_CATEGORIES_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const createCategory = (category) => {
  return async (dispatch) => {
    try {
      await Axios.post("http://localhost:3001/categories", category);
      dispatch(notifiesActions.enqueueNotify("MENU_CREATE_MENU_SUCCESS"));
      dispatch(fetchCategories());
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("MENU_CREATE_MENU_FAIL"));
    }
  };
};

const setCategoriesSearch = (search) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_CATEGORIES_SEARCH", payload: search });
  };
};

const setCategoriesView = (view) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_CATEGORIES_VIEW", payload: view });
  };
};

const setCategoriesDeleteModal = (value) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_CATEGORIES_DELETE_MODAL", payload: value });
  };
};

const setCategoriesCreateModal = (value) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_CATEGORIES_CREATE_MODAL", payload: value });
  };
};

const fetchArticles = () => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await Axios.get("http://localhost:3001/articles");
      dispatch({ type: "MENU_ARTICLES_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const createArticle = (category) => {
  return async (dispatch) => {
    try {
      await Axios.post("http://localhost:3001/articles", category);
      dispatch(notifiesActions.enqueueNotify("MENU_CREATE_MENU_SUCCESS"));
      dispatch(fetchArticles());
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("MENU_CREATE_MENU_FAIL"));
    }
  };
};

const setArticlesSearch = (search) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_ARTICLES_SEARCH", payload: search });
  };
};

const setArticlesView = (view) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_ARTICLES_VIEW", payload: view });
  };
};

const setArticlesDeleteModal = (value) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_ARTICLES_DELETE_MODAL", payload: value });
  };
};

const setArticlesCreateModal = (value) => {
  return (dispatch) => {
    dispatch({ type: "MENU_SET_ARTICLES_CREATE_MODAL", payload: value });
  };
};

export default {
  setQuery,
  setResumeExpand,
  getItem,
  setPage,
  fetchMenus,
  createMenu,
  setMenusSearch,
  setMenusView,
  setMenusDeleteModal,
  setMenusCreateModal,
  fetchCategories,
  createCategory,
  setCategoriesSearch,
  setCategoriesView,
  setCategoriesCreateModal,
  setCategoriesDeleteModal,
  fetchArticles,
  createArticle,
  setArticlesSearch,
  setArticlesView,
  setArticlesDeleteModal,
  setArticlesCreateModal,
};
