import React from "react";
import { Grid, Zoom, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import OrdersIcon from "@material-ui/icons/RoomService";
import PriceIcon from "@material-ui/icons/LocalOffer";
import AssessmentIcon from "@material-ui/icons/Assessment";
import StateCard from "./StateCard";

export default function Index() {
  const theme = useTheme()
  const dashboard = useSelector((state) => state.dashboard);

  return (
    <Grid container spacing={4} alignItems="stretch" direction="row">
      <Zoom in={true} timeout={500}>
        <Grid item xs={4}>
          <StateCard
            title="TOTAL ÓRDENES DEL DÍA"
            value={new Intl.NumberFormat("es-CL").format(
              dashboard.cards.ordersTotal
            )}
            icon={<OrdersIcon style={{ fontSize: 56, color: theme.palette.primary.main }}/>}
            loading={dashboard.loading}
          />
        </Grid>
      </Zoom>
      <Zoom in={true} timeout={500}>
        <Grid item xs={4}>
          <StateCard
            title="TOTAL VENDIDO DEL DÍA"
            value={new Intl.NumberFormat("es-CL", {
              currency: "CLP",
              style: "currency",
            }).format(dashboard.cards.totalSold)}
            icon={<PriceIcon style={{ fontSize: 56, color: theme.palette.primary.main }}/>}
            loading={dashboard.loading}
          />
        </Grid>
      </Zoom>
      <Zoom in={true} timeout={500}>
        <Grid item xs={4}>
          <StateCard
            title="ÓRDENES ÚLTIMA HORA"
            value={new Intl.NumberFormat("es-CL").format(
              dashboard.cards.ordersLastHour
            )}
            icon={<AssessmentIcon style={{ fontSize: 56, color: theme.palette.primary.main }} color="action" />}
            loading={dashboard.loading}
          />
        </Grid>
      </Zoom>
    </Grid>
  );
}
