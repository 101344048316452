const defaultState = {
  loged: "NOT_LOGED",
  profile: 0,
  store: 0,
  storeData: {
    id: 0,
    melt_store: 0,
    description: "",
    address: "",
    city: "",
    createdAt: "",
    updatedAt: "",
  },
  username: "",
  firstname: "",
  loginStatus: "READY",
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "AUTH_LOGIN_SUCCESS": {
      return { ...state, ...payload };
    }
    case "AUTH_LOGIN_FAIL": {
      return defaultState;
    }
    case "AUTH_LOGOUT": {
      return defaultState;
    }
    case "AUTH_SET_STORE_DATA": {
      return { ...state, storeData: payload };
    }
    case "AUTH_SET_LOGIN_STATUS": {
      return { ...state, loginStatus: payload };
    }
    default:
      return state;
  }
}

export default reducer;
