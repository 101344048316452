const now = new Date();

const defaultState = {
	data: [],
	totalRows: 0,
	query: {
		page: 1,
		rows: 30,
		start: new Date(now),
		end: new Date(now.setDate(now.getDate() + 1)),
		status: "0",
		store: "0",
		restaurant: 1,
		application_id: 0,
		order_id: "",
		advancedSearch: false,
	},
	viewModal: false,
	dataModal: null,
	blockResend: false,
	blockJustoSend: false,
  checkedAll: false,
  sendingMassive: false,
  sendedMassive: false,
	newOrdersModal: false,
  newOrdersModalList: [],
};

function reducer(state = defaultState, { type, payload }) {
	switch (type) {
		case "ORDERS_FETCH": {
			return { ...state, ...payload };
		}
		case "ORDERS_SET_QUERY": {
			return { ...state, query: { ...state.query, ...payload } };
		}
		case "ORDERS_SET_PAGE": {
			return { ...state, query: { ...state.query, page: payload } };
		}
		case "ORDERS_SET_VIEW_MODAL": {
			return { ...state, viewModal: payload };
		}
		case "ORDERS_SET_DATA_MODAL": {
			return { ...state, dataModal: payload };
		}
		case "ORDERS_RESET_QUERY": {
			return { ...state, query: defaultState.query };
		}
		case "ORDERS_SET_BLOCK_RESEND": {
			return { ...state, blockResend: payload };
		}
		case "ORDERS_RESET_STATE": {
			return defaultState;
		}
		case "ORDERS_SET_DATA_JUSTO": {
			return { ...state };
		}
		case "ORDERS_SET_DATA_PYA": {
			return { ...state };
		}
		case "ORDERS_SET_DATA_UBER": {
			return { ...state };
		}
		case "ORDERS_SET_BLOCK_JUSTO_SEND": {
			return { ...state, blockJustoSend: payload };
		}
		case "SET_CHECKED_ORDER": {
			const foundOrder = state.data.find(
				(order) => order.order_id === payload.order_id
			);
			if (foundOrder) {
				foundOrder.checked = payload.checked;
			}
			return { ...state, data: [...state.data] };
		}
    case "SENDING_MASSIVE_ORDERS": {
      return { ...state, sendingMassive: payload };
    }
    case "SENDED_MASSIVE_ORDER": {
      return { ...state, sendedMassive: payload };
    }
		case "SET_ALL_CHECKED_ORDER": {
			return {
				...state,
        checkedAll: payload.checked,
				data: state.data.map((order) => {
					return {
						...order,
            checked: payload.checked
					};
				}),
			};
		}
		case "ORDERS_SET_NEW_ORDERS_MODAL": {
      return { ...state, newOrdersModal: payload };
    }
    case "ORDERS_SET_NEW_ORDERS_MODAL_LIST": {
      return { ...state, newOrdersModalList: payload };
    }
		default:
			return state;
	}
}

export default reducer;
