const defaultState = {
	data: [],
	dataJusto: [],
	dataMeltStore: {},
	singleData: null,
	totalRows: 0,
	query: {
		page: 0,
		rows: 30,
		restaurant: 1,
	},
	editViewModal: false,
	viewModal: false,
	dataModal: null,
	editDataModal: null,
	viewError: false,
	logError: null,
	dialButton: false,
};

function reducer(state = defaultState, { type, payload }) {
	switch (type) {
		case "STORES_FETCH": {
			return { ...state, ...payload };
		}
		case "STORES_FETCH_JUSTO": {
			return { ...state, ...payload };
		}
		case "STORE_FETCH_MELT": {
			return { ...state, ...payload };
		}
		case "STORES_SET_QUERY": {
			return { ...state, query: { ...state.query, ...payload } };
		}
		case "STORES_SET_VIEW_ERROR": {
			return { ...state, viewError: payload };
		}
		case "STORES_SET_DATA_MODAL": {
			return { ...state, dataModal: payload };
		}
		case "STORES_RESET_STATE": {
			return defaultState;
		}
		case "STORES_SET_LOGERROR": {
			return { ...state, logError: payload };
		}
		case "STORES_SET_DIALBUTTON": {
			return { ...state, dialButton: payload };
		}
		case "STORES_RESET_QUERY": {
			return { ...state, query: defaultState.query };
		}
		case "STORES_SET_EDIT_VIEW_MODAL": {
			return { ...state, editViewModal: payload };
		}
		case "STORES_SET_EDIT_DATA_MODAL": {
			return { ...state, editDataModal: payload };
		}
		case "STORES_SET_SINGLE_DATA": {
			return { ...state, singleData: payload };
		}
		default:
			return state;
	}
}

export default reducer;
