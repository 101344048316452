import React from "react";
import UberEats from "./UberEats";
import PedidosYa from "./PedidosYa";
import Justo from "./Justo";
import Ecommerce from "./Ecommerce";
import { Grid, Typography } from "@material-ui/core";
import { ECOMMERCE_ID } from "../../../../../constants";

export default function Index({ data }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Productos</Typography>
        <hr />
      </Grid>
      <Grid item xs={12}>
        {(ECOMMERCE_ID.includes(data.application_id)) ? (
          <Ecommerce data={data} />
        ) : data.application_id === 4 ? (
          <PedidosYa data={data} /> )
            : (data.application_id === 5 || data.application_id === 7 || data.application_id === 8 || data.application_id === 9) ?
          ( <Justo data={data} />
          ) : 
         ( <UberEats data={data} />
        )}
      </Grid>
    </Grid>
  );
}
