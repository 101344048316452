const translatePaymentMethod = (paymentType) => {
	const defaultPayment = "Tipo de pago desconocido"
	const valuePayment = {
		"creditcard" : "Crédito",
		"credit_card": "Crédito",
		"Pago con tarjeta - Mercado Pago": "Crédito",
		"debitcard": "Débito",
		"card-upon-delivery": "Tarjeta contra entrega",
		"cash-upon-delivery": "Efectivo contra entrega",
		"fpay": "FPay",
		"debitcardcvv": "Tarjeta de Débito CVV",
		"Pago contra Entrega": "Pago contra entrega",
		"cashondelivery": "Pago contra entrega"
	}

	return valuePayment[paymentType] ?? valuePayment[defaultPayment];

};

export default translatePaymentMethod;
