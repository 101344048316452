import React, { Fragment, useEffect, useState } from "react";
import DialogLogin from "./LoginDialog";
import LoginForm from "./LoginForm";
import { useSelector, useDispatch } from "react-redux";
import authActions from "../../redux/actions/auth.actions";
import { Redirect } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    if (auth.loginStatus === "WELCOME") {
      setTimeout(() => {
        dispatch(authActions.loginStatus("READY"));
        setRedirect("/");
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loginStatus]);

  return (
    <Fragment>
      {auth.loged === "LOGED" && auth.loginStatus === "WELCOME" && (
        <DialogLogin />
      )}
      {auth.loged === "NOT_LOGED" && <LoginForm />}
      {redirect && <Redirect to={redirect} />}
    </Fragment>
  );
}
