import React, { useEffect, useState } from "react";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";

const COLORS = ["#7bc356","#0088FE",  "#e52528", "#FF8042"];

export default function ThisPieChart() {
  const data = useSelector((state) => state.dashboard).charts.topSuppliers;

  const [dataPie, setDataPie] = useState([])

  useEffect(() => {
	  let total = data.reduce((sum, current) => sum += parseInt(current.count), 0)
	  setDataPie(data.map(agg => {
		  return {
			...agg,
			name: agg.name + " " +(agg.count * 100 / total).toFixed(0)  + "%"
		  }
	  }))
  }, [data])

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <PieChart>
          <Legend />
          <Pie
            data={dataPie}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="count"
            label
          >
            {data.map((_entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
