// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { useEffect, useCallback } from "react";
import { Paper, Card, CardContent, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import storesActions from "../../../redux/actions/stores.actions";
import Backdrop from "@material-ui/core/Backdrop";

import HeaderPage from "../../Frames/HeaderPage";
import HeaderContent from "./HeaderContent";
import Table from "./Table";
import ViewError from "./ViewError";
import Search from "./Search";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stores = useSelector((state) => state.stores);

  const initFetch = useCallback(() => {
    dispatch(storesActions.fetch());
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  return (
    <Paper className={classes.root}>
      <Card>
        <Backdrop open={stores.dialButton} style={{ zIndex: 2000 }} />

        <CardContent>
          <HeaderPage content={<HeaderContent />} />
          <Search />
          <Table />
          <ViewError />
          {/* <DailButton /> */}
        </CardContent>
      </Card>
    </Paper>
  );
}
