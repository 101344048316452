import React, { useState, Fragment, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";

import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../../../redux/actions/menu.actions";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Check, Cancel } from "@material-ui/icons";

export default function View() {
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    sellInd: false,
    categories: [],
    price: 0,
    iva: 19,
    spent: false,
    temperature: "heated",
    containAlcohol: false,
    isFood: true,
    cals: 90,
    kjoules: 90,
  });

  useEffect(() => {
    if (error === "DATA_ERROR") {
      setTimeout(() => setError(""), 1500);
    }
  }, [error]);

  const handleCancel = () => {
    dispatch(menuActions.setArticlesView("VIEW"));
  };

  const handleSave = () => {
    if (
      !Boolean(formData.name) ||
      !formData.categories.length ||
      !Boolean(formData.price)
    ) {
      setError("DATA_ERROR");
      return;
    }
    dispatch(menuActions.createArticle(formData));
    dispatch(menuActions.setArticlesView("VIEW"));
  };

  return (
    <Fragment>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h5">Crear nuevo artículo</Typography>
        </Grid>
        <Grid item>
          <ButtonGroup color="primary" size="small">
            <Button startIcon={<Cancel />} onClick={() => handleCancel()}>
              Cancelar
            </Button>
            <Button
              startIcon={<Check />}
              onClick={() => handleSave()}
              variant="contained"
            >
              Crear
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">General</Typography>
          <Typography variant="body2" color="textSecondary">
            Definición general de tu artículo, nombre con el que se venderá,
            descripción que le aparecerá al usuario y forma de venta
            (independiente o con menú).
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            label="Nombre del artículo"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            error={error === "DATA_ERROR"}
          />
          <FormControlLabel
            style={{ marginTop: 10, marginLeft: 10 }}
            control={<Checkbox name="checkedB" color="primary" />}
            label="Este artículo se vende de forma independiente"
            value={formData.sellInd}
            onChange={(e) =>
              setFormData({ ...formData, sellInd: e.target.checked })
            }
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            label="Descripción"
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            error={error === "DATA_ERROR"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6">Categorías</Typography>
          <Typography variant="body2" color="textSecondary">
            Categorías en la que aparecerá el artículo. Luego se enlazará
            automaticamente con los menús que contengan las categorías
            seleccionadas
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={menu.categories.data}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            fullWidth
            value={formData.categories}
            onChange={(_e, k) => setFormData({ ...formData, categories: k })}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Categorías que contendrán el artículo"
                placeholder="Categorías"
                margin="dense"
                error={error === "DATA_ERROR"}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6">Precio y entrega</Typography>
          <Typography variant="body2" color="textSecondary">
            Aquí defines el precio de venta de tu artículo junto al impuesto al
            valor agregado que corresponda según tu región (IVA). Tembién define
            si el artículo está actualmente en stock y la temperatura de
            consumo.
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            margin="dense"
            label="Precio determinado"
            fullWidth
            error={error === "DATA_ERROR"}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
            }}
            value={formData.price.toString()}
            onChange={(e) =>
              setFormData({ ...formData, price: parseInt(e.target.value) })
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            margin="dense"
            label="IVA"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment>%</InputAdornment>,
            }}
            value={formData.iva.toString()}
            onChange={(e) =>
              setFormData({ ...formData, iva: parseInt(e.target.value) })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            style={{ marginTop: 10, marginLeft: 10 }}
            control={<Checkbox name="checkedB" color="primary" />}
            label="El producto se encuentra agotado"
            value={formData.spent}
            onChange={(e) =>
              setFormData({ ...formData, spent: e.target.checked })
            }
          />
        </Grid>
        <Grid item xs={12} />

        <Grid item xs={6}>
          <Typography
            variant="body1"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Este artículo se sirve:{" "}
          </Typography>
          <ToggleButtonGroup
            orientation="horizontal"
            size="small"
            value={formData.temperature}
            exclusive
            onChange={(e, value) =>
              setFormData({ ...formData, temperature: value })
            }
          >
            <ToggleButton value="heated" aria-label="heated">
              Caliente
            </ToggleButton>
            <ToggleButton value="cold" aria-label="cold">
              Frío
            </ToggleButton>
            <ToggleButton value="unheated" aria-label="unheated">
              Sin calentar
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6">Personalizar artículo</Typography>
          <Typography variant="body2" color="textSecondary">
            Los grupos modificadores permiten a los clientes utilizar
            ingredientes, guarniciones y otros elementos para personalizar los
            artículos.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6">Configuración de alcohol</Typography>
          <Typography variant="body2" color="textSecondary">
            Configuración alcohólica del artículo
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="body1"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            ¿Este artículo contiene alcohol?
          </Typography>
          <ToggleButtonGroup
            orientation="horizontal"
            size="small"
            value={formData.containAlcohol}
            exclusive
            onChange={(e, value) =>
              setFormData({ ...formData, containAlcohol: value })
            }
          >
            <ToggleButton value={true} aria-label="true_">
              Sí
            </ToggleButton>
            <ToggleButton value={false} aria-label="false_">
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={8}>
          <FormControlLabel
            style={{ marginTop: 10, marginLeft: 10 }}
            control={<Checkbox name="checkedB" color="primary" />}
            label="Este artículo puede considerarse legalmente comida"
            value={formData.spent}
            onChange={(e) =>
              setFormData({ ...formData, isFood: e.target.isFood })
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <hr />
          <Typography variant="h6">Valores energéticos</Typography>
          <Typography variant="body2" color="textSecondary"></Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            margin="dense"
            value={formData.cals}
            onClick={(e) => setFormData({ ...formData, cals: e.target.value })}
            fullWidth
            label="Calorías"
            InputProps={{
              endAdornment: <InputAdornment>cal</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            margin="dense"
            value={formData.kjoules}
            onClick={(e) =>
              setFormData({ ...formData, kjoules: e.target.value })
            }
            fullWidth
            label="Kilojulios"
            InputProps={{
              endAdornment: <InputAdornment>kJ</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
