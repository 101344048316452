import React, { Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@material-ui/core";

export default function Carditem({ name, price, menus, description }) {
  return (
    <Card>
      <CardContent style={{ padding: 0 }}>
        <Fragment>
          <img
            alt="imagen del producto"
            src="https://d1uz88p17r663j.cloudfront.net/resized/8689e8d974203563ddcc9bbff91323c2_MG_CHORIZOBURGER_Main-880x660_448_448.png"
            style={{ width: "100%" }}
          />
        </Fragment>
        <Grid
          container
          spacing={1}
          style={{
            paddingTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 10,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h5">{name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Menús: {menus.join(", ")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Descripción: {description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider light />
            <Typography variant="h5" align="right">
              Valor: ${price}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
