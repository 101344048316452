
import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Grid, Divider } from "@material-ui/core";
import { TreeView } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import StyledTreeItem from "./StyledTreeItem";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: "100%",
  },
});

export default function Products({ data }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (data.items) {
      setProducts(data.items);
    }
  }, [data]);

  const handleToggle = (_, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (_, nodeIds) => {
    setSelected(nodeIds);
  };

  const formatPrice = (price) => {
    let temp = price.replace("CLP", "").replace(",", ".").replace(" ", "");
    if (temp[0] !== "$") {
      temp = "$" + temp;
    }
    return temp;
  };

  return (
    <Fragment>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        disableSelection
      >
        {products.length > 0 && products.map((product, i) => {
          return (
            <StyledTreeItem
      key={"item_0_" + i}
      nodeId={"item_0_" + i}
      labelText={product.Title}
      labelInfo={"(" + product.Quantity + ") "}
    >
      {product.subitems.map((subItem, j) => {
        if (subItem.Price || subItem.Quantity) {
          return (
            <StyledTreeItem
              key={"subitem_1_" + j}
              nodeId={"subitem_1_" + j}
              labelText={subItem.Title}
              labelInfo={"(" + subItem.Quantity + ")"}
            />
          );
        } else {
          return (
            <Fragment key={"null_1_" + j}>
              {subItem.subitems.map((subSubItem, k) => {
                if (subSubItem.Price || subSubItem.Quantity) {
                  return (
                    <StyledTreeItem
                      key={"subsubitem_2_" + k}
                      nodeId={"subsubitem_2_" + k}
                      labelText={subSubItem.Title}
                      labelInfo={"(" + subSubItem.Quantity + ")"}
                    />
                  );
                } else {
                  return <Fragment key={"null_2_" + k} />;
                }
              })}
            </Fragment>
          );
        }
      })}
    </StyledTreeItem>
          );
        })}
      </TreeView>
      <Divider light style={{ margin: 3 }} />
      <Grid container justify="space-between" style={{ padding: 3 }}>
        <Grid item>
          <Typography
            variant="body2"
            color="textSecondary"
            align="right"
          ></Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary" align="right">
            {formatPrice(data.amount)}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
}

Products.propTypes = {
  data: PropTypes.object.isRequired,
};










