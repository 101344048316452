import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export default function AlertComponent({ severity, text }) {
  return (
    <Fragment>
      <Alert elevation={6} severity={severity} variant="filled">
        <Typography variant="body1">{text}</Typography>
      </Alert>
    </Fragment>
  );
}
