import React from "react";
import {
  Grid,
  Button,
  Avatar,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import authActions from "../../redux/actions/auth.actions";

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 256,
    textAlign: "center",
    padding: 0,
    margin: 0,
  },
  avatar: {
    width: 100,
    height: 100,
    margin: "auto",
  },
}));

export default function ProfileCard() {
  const styles = useStyles();
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  return (
    <div className={styles.card}>
      <Grid container justify="center">
        <Grid item>
          <Avatar style={{ width: 60, height: 60, margin: "auto" }}>
            {auth.firstname[0]}
          </Avatar>
        </Grid>
      </Grid>
      <Typography variant="h6" style={{ marginTop: 10 }}>
        {auth.firstname}
      </Typography>
      <Typography variant="body2" style={{ marginBottom: 6 }}>
        {auth.username}
      </Typography>
      <Divider light />
      <Typography variant="body2" style={{ margin: 6 }}>
        {auth.profile === 1
          ? "Administrador"
          : "Sucursal: " + auth.storeData.description}
      </Typography>
      <Divider light />

      <Grid container style={{ padding: 10 }}>
        <Grid item xs={12}>
          <Link to={"/profile"} style={{ textDecoration: "none" }}>
            <Button startIcon={<PersonIcon />} fullWidth>
              Perfil
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<ExitToAppIcon />}
            fullWidth
            onClick={() => dispatch(authActions.logout())}
          >
            Cerrar sesión
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
