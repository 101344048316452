import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import Status from "./Status";
import PropTypes from "prop-types";
import Products from "./Products";
import General from "./General";
import Contact from "./Contact";
import ContactJusto from "./ContactJusto";

import CopyToClipboard from "./CopyToClipboard";
import { useDispatch /* useSelector */ } from "react-redux";
import ordersActions from "../../../../redux/actions/orders.actions";
import { SportsMotorsports as SportsMotorsportsIcon } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Delivery from "./Delivery";
import { isEnableDelivery } from "../../../../resources/utils/externalDelivery";
import { ECOMMERCE_ID } from "../../../../constants";
import { ERROR, MESSAGE_ERROR } from "../../../../constants/uber";

export default function Content({ data }) {
	const dispatch = useDispatch();
	/* const orders = useSelector((state) => state.orders); */
	const [dataDelivery, setDataDelivery] = useState(null);
	/* 	const [isEnableButtons, setIsEnableButtons] = useState(true); */
	/* 	const [isEnableButtons, setIsEnableButtons] = useState(true); */
	/* 	const [isDisabledButton, setIsDisabledButton] = React.useState(true); */

	const [dataButton, setDataButton] = useState({
		pya: {
			disabled: false,
			loading: false,
		},
		justo: {
			disabled: false,
			loading: false,
		},
		uber: {
			disabled: false,
			loading: false,
		},
	});

	const options = ({ action, data }) => {
		if (action === "ORDERS_SET_DATA_JUSTO") {
			setDataButton({
				pya: {
					disabled: true,
					loading: false,
				},
				justo: {
					disabled: true,
					loading: true,
				},
				uber: {
					disabled: true,
					loading: false,
				},
			});
			dispatch(ordersActions.sendJusto(data));
		} else if (action === "ORDERS_SET_DATA_PYA") {
			setDataButton({
				pya: {
					disabled: true,
					loading: true,
				},
				justo: {
					disabled: true,
					loading: false,
				},
				uber: {
					disabled: true,
					loading: false,
				},
			});
			dispatch(ordersActions.sendPya(data));
		} else if (action === "ORDERS_SET_DATA_UBER") {
			setDataButton({
				pya: {
					disabled: true,
					loading: false,
				},
				justo: {
					disabled: true,
					loading: false,
				},
				uber: {
					disabled: true,
					loading: true,
				},
			});
			dispatch(ordersActions.sendUber(data));
		}
	};

	/* 	React.useEffect(() => {
		if (orders.blockJustoSend || data.statusJusto === "done") {
			setIsDisabledButton(true);
		} else {
			setIsDisabledButton(false);
		}
	}, [data.statusJusto, orders.blockJustoSend]); */

	useEffect(() => {
		if (data) {
			setDataButton({
				pya: {
					disabled: false,
					loading: false,
				},
				justo: {
					disabled: false,
					loading: false,
				},
				uber: {
					disabled: false,
					loading: false,
				},
			});
			if (data.statusJusto === "done") {
				setDataDelivery({
					type: "justo",
					name: "Justo",
					url: data.justo_response.trackingURL,
					data: data.justo_response,
				});
			} else if (data.pya_response?.success) {
				setDataDelivery({
					type: "pya",
					name: "Pedidos Ya",
					url: data.pya_response.response.shareLocationUrl,
					data: data.pya_response.response,
				});
			} else if(data.statusUber === "success") {
				setDataDelivery({
					type: "uber",
					name: "Uber",
					url: data.uber_response.tracking_url,
					data: data.uber_response,
				});
			} else if (data.captain_response?.success) {
				setDataDelivery({
					type: "captain",
					name: "Captain.AI",
					url: data.captain_response.tracking_link,
					data: data.captain_response,
				});

			} else if (data.statusUber === ERROR) {
				setDataDelivery({
					type: "uber_error",
					name: "Uber",
					url: MESSAGE_ERROR,
					data: data.uber_response,
				})
			}
		}
	}, [data]);

	return (
		<Grid container spacing={4} justify="center">
			<Grid item xs={12}>
				<Status data={data} />
			</Grid>

			<Grid item xs={6}>
				<General data={data} />
				{(ECOMMERCE_ID.includes(data.application_id) || data.application_id === 8) && <Contact data={data} />}
				{data.application_id === 7 && <ContactJusto data={data} />}
			</Grid>

			<Grid item xs={6}>
				<Products data={data} />
			</Grid>

			<Grid item xs={12}>
				<hr style={{ margin: 0 }} />
			</Grid>
			<Grid item xs={12} align="center">
				<CopyToClipboard text={data.order_id} />
			</Grid>
			{(!dataDelivery || data.statusUber === ERROR) && (
				<>
					{isEnableDelivery(data) && data.justoDelivery && (
						<Grid item xs={3} align="center">
							<Button
								variant="contained"
								size="medium"
								color="primary"
								disabled={dataButton.justo.disabled}
								onClick={() =>
									options({
										action: "ORDERS_SET_DATA_JUSTO",
										data,
									})
								}
							>
								<SportsMotorsportsIcon
									style={{ marginRight: 5 }}
								/>
								JUSTO
								{dataButton.justo.loading && (
									<CircularProgress size={15} />
								)}
							</Button>
						</Grid>
					)}

					{isEnableDelivery(data) && data.is_pya_delivery && (
						<Grid item xs={3} align="center">
							<Button
								variant="contained"
								size="medium"
								color="primary"
								disabled={dataButton.pya.disabled}
								onClick={() =>
									options({
										action: "ORDERS_SET_DATA_PYA",
										data,
									})
								}
							>
								<SportsMotorsportsIcon
									style={{ marginRight: 5 }}
								/>
								Pedidos Ya
								{dataButton.pya.loading && (
									<CircularProgress size={15} />
								)}
							</Button>
						</Grid>
					)}

					{isEnableDelivery(data) && data.is_uber_delivery && (
						<Grid item xs={3} align="center">
							<Button
								variant="contained"
								size="medium"
								color="primary"
								disabled={dataButton.uber.disabled}
								onClick={() =>
									options({
										action: "ORDERS_SET_DATA_UBER",
										data,
									})
								}
							>
								<SportsMotorsportsIcon
									style={{ marginRight: 5 }}
								/>
								Uber Driver
								{dataButton.uber.loading && (
									<CircularProgress size={15} />
								)}
							</Button>
						</Grid>
					)}
				</>
			)}

			{dataDelivery && (
				<>
					<Grid item xs={12}>
						<hr style={{ margin: 0 }} />
					</Grid>
					<Delivery data={dataDelivery} />
				</>
			)}
		</Grid>
	);
}

Content.propTypes = {
	data: PropTypes.object.isRequired,
};
