import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CopyToClipboard from "./CopyToClipboard";
import { SportsMotorsports as SportsMotorsportsIcon } from "@material-ui/icons";

export default function Delivery({ data }) {
	return (
		<Grid item xs={12} align="center">
			<Grid item xs={12}>
				<SportsMotorsportsIcon style={{ marginRight: 5 }} />
				<Typography variant="h6">{data.name}</Typography>
			</Grid>
			<Grid item xs={12}>
				<CopyToClipboard text={data.url} />
			</Grid>
		</Grid>
	);
}
