import React, { Fragment, useEffect, useCallback } from "react";
import { TextField, Grid, Button } from "@material-ui/core";
import {
  Search as SearchIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import ordersActions from "../../../../../redux/actions/orders.actions";

export default function Index() {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);

  const refreshAdvancedSearch = useCallback(() => {
	dispatch(ordersActions.setQueryWithoutRefresh({ order_id: "" }));
	dispatch(ordersActions.fetch());
  }, [dispatch]);

  useEffect(() => {
    if (!orders.query.advancedSearch) {
		refreshAdvancedSearch()
    }
  }, [orders.query.advancedSearch, refreshAdvancedSearch]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Button
          style={{ height: 40, marginTop: 4 }}
          color="primary"
          startIcon={<SearchIcon />}
          endIcon={
            orders.query.advancedSearch ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )
          }
          size="small"
          onClick={() =>
            dispatch(
              ordersActions.setQueryWithoutRefresh({
                advancedSearch: !orders.query.advancedSearch,
              })
            )
          }
        >
          Búsqueda avanzada
        </Button>
      </Grid>
      {orders.query.advancedSearch && (
        <Fragment>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="Id de orden"
              margin="dense"
              value={orders.query.order_id}
              onChange={(e) =>
                dispatch(
                  ordersActions.setQueryWithoutRefresh({
                    order_id: e.target.value.trim(),
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              startIcon={<SearchIcon />}
              style={{ height: 40, marginTop: 4 }}
              color="primary"
              onClick={() => dispatch(ordersActions.fetch())}
            >
              Buscar
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
}
