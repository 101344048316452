import axios from "axios";
import appActions from "./app.actions";
import authActions from "./auth.actions";
import notifiesAction from "./notifies.actions";

const fetch = () => {
	return async (dispatch) => {
		try {
			dispatch(appActions.setLoading(true));
			const response = await axios.get("/teststore");
			dispatch({
				type: "STORES_FETCH",
				payload: { data: response.data },
			});
			dispatch(appActions.setLoading(false));
		} catch (err) {
			dispatch(appActions.setLoading(false));
			dispatch(authActions.logoutIfNotAuth(err));
		}
	};
};

const fetchJusto = () => {
	return async (dispatch) => {
		try {
			dispatch(appActions.setLoading(true));
			const response = await axios.get("/stores-justo");
			console.log(response.data);
			dispatch({
				type: "STORES_FETCH_JUSTO",
				payload: { dataJusto: response.data },
			});
			dispatch(appActions.setLoading(false));
		} catch (err) {
			dispatch(appActions.setLoading(false));
			dispatch(authActions.logoutIfNotAuth(err));
		}
	};
};

const setQuery = (query) => {
	return (dispatch) => {
		dispatch({ type: "STORES_SET_QUERY", payload: query });
		dispatch(fetch());
	};
};

const setSingleData = (data) => {
	return (dispatch) => {
		dispatch({ type: "STORES_SET_SINGLE_DATA", payload: data });
	};
};

const setDataModal = (data) => {
	return (dispatch) => {
		dispatch({ type: "STORES_SET_DATA_MODAL", payload: data });
	};
};

const setViewModal = (value) => {
	return (dispatch) => {
		dispatch({ type: "STORES_SET_VIEW_MODAL", payload: value });
	};
};

const setEditDataModal = (data) => {
	return (dispatch) => {
		dispatch({ type: "STORES_SET_EDIT_DATA_MODAL", payload: data });
	};
};

const setEditViewModal = (value) => {
	return (dispatch) => {
		dispatch({ type: "STORES_SET_EDIT_VIEW_MODAL", payload: value });
	};
};

const getStore = (payload) => {
	return async (dispatch) => {
		try {
			// dispatch(setEditDataModal(payload));
			const response = await axios.get(`/store/${payload.melt_store}`);

			// /:store_id
			console.log(response);
			dispatch(setSingleData(response.data));
			// dispatch(appActions.setLoading(false));
		} catch (err) {
			console.log(err);

			// dispatch(appActions.setLoading(false));
			// dispatch(authActions.logoutIfNotAuth(err));
		}
	};
};

const editStore = (payload) => {
	return async (dispatch) => {
		try {
			console.log(payload);
			const response = await axios.post(
				`/stores-justo/edit/${payload.id}`,
				payload
			);
			console.log(response.status);
			// dispatch(setEditDataModal(payload));
			if (response.status === 200) {
				dispatch(setSingleData(null));
				dispatch(fetchJusto());
				dispatch(
					notifiesAction.enqueueNotify(
						"STORES_EDIT_SUCCESS",
						payload.store
					)
				);
				return "SUCCESS";
			} else {
				console.log("HOLAMUNDO");
				dispatch(notifiesAction.enqueueNotify("STORES_EDIT_FAIL"));
				return "ERROR";
			}

			// dispatch(appActions.setLoading(false));
		} catch (err) {
			console.log(err);
			dispatch(notifiesAction.enqueueNotify("STORES_EDIT_FAIL"));
			return "ERROR";
			// dispatch(appActions.setLoading(false));
			// dispatch(authActions.logoutIfNotAuth(err));
		}
	};
};

const getMeltStore = (storeId) => {
	return async (dispatch) => {
		try {
			dispatch(appActions.setLoading(true));
			const response = await axios.post(`/store/getMeltStore`, {
				storeId,
			});
			console.log("storeMelt---" + response.data);
			dispatch({
				type: "STORE_FETCH_MELT",
				payload: { dataMeltStore: response.data },
			});
			dispatch(appActions.setLoading(false));
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(authActions.logoutIfNotAuth(error));
		}
	};
};

const closeStore = (
	value,
	updateTempClosedAt,
	time,
	userName,
	email,
	tempClosed
) => {
	return async (dispatch) => {
		try {
			const response = await axios.post(`/store/closeMeltStore`, {
				value,
				updateTempClosedAt,
				time,
				userName,
				email,
				tempClosed,
			});
			if (response.status === 200) {
				dispatch(notifiesAction.enqueueNotify("STORES_EDIT_SUCCESS"));
				return "SUCCESS";
			}
		} catch (error) {
			console.log(error);
			dispatch(notifiesAction.enqueueNotify("STORES_EDIT_FAIL"));
			return "ERROR";
		}
	};
};

const setViewError = (value) => {
	return (dispatch) => {
		dispatch({ type: "STORES_SET_VIEW_ERROR", payload: value });
	};
};

const validate = (data) => {
	return async (dispatch) => {
		try {
			dispatch(appActions.setLoading(true));
			let body = {
				method: "PUT",
				store_id: data.storeId,
			};
			await axios.post("/teststore", body);

			dispatch(notifiesAction.enqueueNotify("STORES_VALIDATE_SUCCESS"));
			dispatch(appActions.setLoading(false));
			dispatch(fetch());
		} catch (err) {
			dispatch(notifiesAction.enqueueNotify("STORES_VALIDATE_FAIL"));
			dispatch(appActions.setLoading(false));
		}
	};
};

const sendPOS = (data) => {
	return async (dispatch) => {
		try {
			dispatch(appActions.setLoading(true));
			let body = {
				method: "POST",
				store_id: data.storeId,
			};
			await axios.post("/teststore", body);

			dispatch(notifiesAction.enqueueNotify("STORES_SENDPOS_SUCCESS"));
			dispatch(appActions.setLoading(false));
			dispatch(fetch());
		} catch (err) {
			dispatch(notifiesAction.enqueueNotify("STORES_SENDPOS_FAIL"));
			dispatch(appActions.setLoading(false));
		}
	};
};

const sendALL = (data) => {
	return async (dispatch) => {
		try {
			dispatch(appActions.setLoading(true));
			let body = {
				method: "ALL",
				store_id: data.storeId,
			};
			await axios.post("/teststore", body);

			dispatch(notifiesAction.enqueueNotify("STORES_SENDALL_SUCCESS"));
			dispatch(appActions.setLoading(false));
			dispatch(fetch());
		} catch (err) {
			dispatch(notifiesAction.enqueueNotify("STORES_SENDALL_FAIL"));
			dispatch(appActions.setLoading(false));
		}
	};
};

const getError = (storeID) => {
	return async (dispatch) => {
		try {
			const response = await axios.get("/teststore/" + storeID);
			dispatch(setLogError(response.data));
		} catch (err) {
			console.log(err);
		}
	};
};

const setLogError = (value) => {
	return (dispatch) =>
		dispatch({ type: "STORES_SET_LOGERROR", payload: value });
};

const setDialButton = (value) => {
	return (dispatch) => {
		dispatch({ type: "STORES_SET_DIALBUTTON", payload: value });
	};
};

const resetQuery = () => {
	return async (dispatch) => {
		await dispatch({ type: "STORES_RESET_QUERY" });
		await dispatch(fetch());
	};
};

export default {
	fetch,
	setQuery,
	setDataModal,
	setViewModal,
	setViewError,
	validate,
	sendPOS,
	sendALL,
	getError,
	setLogError,
	setDialButton,
	resetQuery,
	getStore,
	setEditViewModal,
	setEditDataModal,
	fetchJusto,
	editStore,
	closeStore,
	getMeltStore,
};
