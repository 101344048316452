/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Paper, makeStyles, Card, CardContent } from "@material-ui/core";
// import { useDispatch } from "react-redux";
// import dashboardActions from "../../../redux/actions/dashboard.actions";
import HeaderPage from "../../Frames/HeaderPage";
import HeaderContent from "./HeaderContent";
import Cards from "./Cards";
import Charts from "./Charts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  // const dispatch = useDispatch();

  useEffect(() => {
    // const start = () => {
    //   dispatch(dashboardActions.getDashboard());
    // };
    // start();
  }, []);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <HeaderPage content={<HeaderContent />} />
          <Cards />
          <hr />
          <Charts />
        </CardContent>
      </Card>
    </Paper>
  );
}
