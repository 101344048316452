import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Paper,
	Button,
	ButtonGroup,
	makeStyles,
	withStyles,
	useTheme,
	Chip,
	Grid,
	Tooltip,
	CircularProgress,
	Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
	Visibility as VisibilityIcon,
	Check as CheckIcon,
	Warning as WarningIcon,
	SportsMotorsports,
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import ordersActions from "../../../redux/actions/orders.actions";
import storesActions from "../../../redux/actions/stores.actions";
import JustoImage from "../../../assets/images/justo.png";
import PyaImage from "../../../assets/images/pya.png";
import UberImage from "../../../assets/images/uber.png";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { ERROR } from "../../../constants/uber";
import { ROW, ID_CANCELED } from "../../../constants";

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = {
	1: [
		{
			id: "selector",
			numeric: false,
			disablePadding: false,
			label: "Seleccion",
		},
		{
			id: "application_id",
			numeric: false,
			disablePadding: false,
			label: "Agregador",
		},
		{
			id: "firstname",
			numeric: false,
			disablePadding: false,
			label: "Nombre",
		},
		{
			id: "store",
			numeric: false,
			disablePadding: false,
			label: "Sucursal",
		},
		{
			id: "orderDate",
			numeric: false,
			disablePadding: false,
			label: "Fecha",
		},
		{
			id: "amountNumber",
			numeric: false,
			disablePadding: false,
			label: "Monto",
		},
		{ id: "status", numeric: false, disablePadding: true, label: "Estado" },
		{
			id: "options",
			numeric: false,
			disablePadding: false,
			label: "Opciones",
		},
		{
			id: "delivery",
			numeric: false,
			disablePadding: false,
			label: "Delivery Externo",
		},
	],
	2: [
		{
			id: "selector",
			numeric: false,
			disablePadding: false,
			label: "Seleccion",
		},
		{
			id: "application_id",
			numeric: false,
			disablePadding: false,
			label: "Agregador",
		},
		{
			id: "firstname",
			numeric: false,
			disablePadding: false,
			label: "Nombre",
		},

		{
			id: "orderDate",
			numeric: false,
			disablePadding: false,
			label: "Fecha",
		},
		{
			id: "amountNumber",
			numeric: false,
			disablePadding: false,
			label: "Monto",
		},
		{ id: "state", numeric: false, disablePadding: true, label: "Estado" },
		{
			id: "options",
			numeric: false,
			disablePadding: false,
			label: "Opciones",
		},
		{
			id: "delivery",
			numeric: false,
			disablePadding: false,
			label: "Delivery Externo",
		},
	],
};

function EnhancedTableHead(props) {
	const {
		classes,
		order,
		orderBy,
		onRequestSort,
		/* 	handleChangeAll,
		checked, */
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead>
			<TableRow>
				{headCells[props.profile].map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{/* {headCell.id === "selector" && (
							<Checkbox
								onChange={() => handleChangeAll(!props.checked)}
								checked={checked}
							/>
						)} */}
						{headCell.id !== "selector" && (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={
									orderBy === headCell.id ? order : "asc"
								}
								onClick={createSortHandler(headCell.id)}
							>
								<strong>{headCell.label}</strong>
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
}));

export default function EnhancedTable() {
	const dispatch = useDispatch();
	const orders = useSelector((state) => state.orders);
	const utils = useSelector((state) => state.utils);
	const auth = useSelector((state) => state.auth);
	const stores = useSelector((state) => state.stores);
	const theme = useTheme();
	const classes = useStyles();
	const [order, setOrder] = useState("desc");
	const [orderBy, setOrderBy] = useState("");
	const [dense] = useState(false);
	const [rows, setRows] = useState([]);

	//const [checked, setChecked] = React.useState(false);
	const [checkedAll, setCheckedAll] = useState(false);
	const [enableMasive, setEnableMasive] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [deliverySelected, setDeliverySelected] = useState("");
	const [deliveries, setDeliveries] = useState([]);
	const [sendingMassive, setSendingMassive] = useState(false);

	const handleChange = (row, value) => {
		dispatch(ordersActions.setCheckedOrder(row.order_id, !row.checked));
	};
	const handleDeliverySelected = (event) => {
		setDeliverySelected(event.target.value);
	};
	const handleChangeAll = () => {
		dispatch(ordersActions.setAllCheckedOrder(!orders.checkedAll));
	};

	const handleOpenCloseModal = (value) => {
		setOpenModal(value);
	};

	const handleSendMassive = () => {
		const ordersChecked = orders.data.filter((order) => order.checked);
		dispatch(ordersActions.sendMassive(ordersChecked, deliverySelected));
	};

	// const handleCloseStore = async (time) => {
	// 	await dispatch(
	// 		storesActions.closeStore(
	// 			auth.storeData.melt_store,
	// 			stores.dataMeltStore.tempClosed,
	// 			time
	// 		)
	// 	);
	// 	dispatch(storesActions.getMeltStore(auth.storeData.melt_store));
	// };

	// const handleCloseStore = useCallback(
	// 	async (time) => {
	// 		await dispatch(
	// 			storesActions.closeStore(
	// 				auth.storeData.melt_store,
	// 				stores.dataMeltStore.tempClosed,
	// 				time
	// 			)
	// 		);
	// 		dispatch(storesActions.getMeltStore(auth.storeData.melt_store));
	// 	},
	// 	[auth.storeData.melt_store, dispatch, stores.dataMeltStore.tempClosed]
	// );

	useEffect(() => {
		if (
			auth.profile === 2 &&
			Object.entries(stores.dataMeltStore).length === 0
		) {
			dispatch(storesActions.getMeltStore(auth.storeData.melt_store));
		}
	}, [
		auth.profile,
		stores.dataMeltStore,
		dispatch,
		auth.storeData.melt_store,
	]);
	useEffect(() => {
		const firstOrder = orders.data.find((order) => order.checked);
		if (firstOrder) {
			const deliveriesArray = [];
			if (firstOrder.enable_pya_delivery) {
				deliveriesArray.push({
					value: "pya",
					name: "Pedidos Ya",
				});
			}
			if (firstOrder.enable_justo_delivery) {
				deliveriesArray.push({
					value: "justo",
					name: "Justo",
				});
			}
			console.log(deliveriesArray);
			if (deliveries.length) {
				setDeliverySelected(deliveriesArray[0].value);
			}
			setDeliveries(deliveriesArray);
		}
		const ordersChecked = orders.data.filter((order) => order.checked);
		if (
			firstOrder &&
			ordersChecked.every(
				(order) =>
					order.enable_pya_delivery ===
						firstOrder.enable_pya_delivery &&
					order.enable_justo_delivery ===
						firstOrder.enable_justo_delivery
			)
		) {
			setEnableMasive(true);
		} else {
			setEnableMasive(false);
		}
		// eslint-disable-next-line
	}, [orders]);

	useEffect(() => {
		setSendingMassive(orders.sendingMassive);
	}, [orders.sendingMassive]);

	useEffect(() => {
		if (orders.sendedMassive) {
			handleOpenCloseModal(false);
		}
	}, [orders.sendedMassive]);

	useEffect(() => {
		setCheckedAll(orders.checkedAll);
	}, [orders.checkedAll]);
	useEffect(() => {
		if (orders.data) {
			let temp = orders.data.map((order) => {
				//transformar fecha

				let dateParts = order.date.split("-");
				let hourParts = order.hour.split(":");

				let orderDate = new Date(
					+dateParts[2],
					dateParts[1] - 1,
					+dateParts[0],
					hourParts[0],
					hourParts[1],
					hourParts[2]
				);

				// transformar monto

				let amountNumber = parseInt(
					order.amount
						.substr(1, order.amount.lengths)
						.replace(".", "")
				);

				// Calcular estado
				let status = 2;
				if (order.status_order_id === 13) {
					status = 1;
				} else if (order.status_order_id === 9) {
					status = 2;
				} else {
					status = 0;
				}

				return {
					...order,
					orderDate,
					amountNumber,
					status,
				};
			});
			setRows(temp);
		}
	}, [orders.data]);

	const handleRequestSort = (_event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (_event, page) => {
		if (page > orders.query.page) {
			if (rows.length === orders.query.rows) {
				dispatch(ordersActions.setPage(page));
			}
		} else {
			dispatch(ordersActions.setPage(page));
		}
	};

	const emptyRows = orders.query.rows - rows.length;

	const options = ({ action, data }) => {
		if (action === "VIEW") {
			dispatch(ordersActions.getOrder(data));
		}
		if (action === "RESEND") {
			dispatch(ordersActions.resend(data));
		}
	};

	const background = (row) => {
		const now = new Date();

		if (row.status_order_id === 9 && row.statusUber !== ERROR && !row.pya_response?.error && row.statusJusto !== ERROR) {
			if (now - row.orderDate < ROW) {
				return theme.palette.infoSoft.main;
			} else {
				return theme.palette.background.paper;
			}
		} else if (row.status_order_id === ID_CANCELED) {
			return theme.palette.warningSoft.main;
		} else if (row.statusUber === ERROR || row.statusJusto === ERROR || (row.enable_captain_delivery && !row.captain_response)) {
			return theme.palette.secondary.light;
		} else if (row.pya_response?.error) {
			return theme.palette.secondary.light;
		} else {
			return theme.palette.errorSoft.main;
		}
	};

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid container justify="space-between">
					<Grid item style={{ margin: 16 }}>
						{orders.blockResend && (
							<Grid
								container
								alignItems="center"
								spacing={1}
								justify="space-between"
								style={{
									border:
										"solid 1px " + theme.palette.info.main,
									borderRadius: 4,
								}}
							>
								<Grid item>
									<CircularProgress
										size={20}
										style={{ marginTop: 4, marginLeft: 4 }}
									/>
								</Grid>
								<Grid item>
									<Typography
										variant="body2"
										style={{ marginRight: 4 }}
									>
										Reinsertando orden
									</Typography>
								</Grid>
							</Grid>
						)}
					</Grid>
					<Grid item style={{ margin: 16 }}>
						<Pagination
							count={Math.ceil(
								orders.totalRows / orders.query.rows
							)}
							page={orders.query.page}
							variant="outlined"
							shape="rounded"
							onChange={handleChangePage}
							color="primary"
						/>
					</Grid>
				</Grid>
				<TableContainer>
					<Grid container direction="row" rowSpacing={3}>
						<Button
							startIcon={<SportsMotorsports />}
							style={{
								height: 40,
								marginBottom: 6,
								marginLeft: 5,
							}}
							color="primary"
							size="small"
							variant="contained"
							disabled={!enableMasive}
							onClick={() => handleOpenCloseModal(true)}
						>
							Despacho Masivo
						</Button>
					</Grid>
					<Table
						className={classes.table}
						size="small"
						aria-labelledby="Ordenes"
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
							profile={auth.profile}
							checked={checkedAll}
							handleChangeAll={handleChangeAll}
						/>
						<TableBody>
							{stableSort(
								rows,
								getComparator(order, orderBy)
							).map((row, index) => (
								<TableRow
									hover
									tabIndex={-1}
									key={index}
									style={{ background: background(row) }}
								>
									<StyledTableCell align="left">
										<Checkbox
											checked={row.checked ?? false}
											color={"primary"}
											disabled={
												row.statusJusto === "done"
													? true
													: row.pya_response?.success
													? true
													: !row.enable_justo_delivery &&
													  !row.enable_pya_delivery
													? true
													: false
											}
											onChange={() =>
												handleChange(
													row,
													row.checked ?? false
												)
											}
											/* 	inputProps={{
											"aria-label": "primary checkbox",
										}} */
										/>
									</StyledTableCell>
									<StyledTableCell align="left">
										<img
											alt="logo_app"
											style={{
												maxWidth: 44,
												maxHeight: 44,
											}}
											src={
												utils.appLogos[
													row.application_id
												]
											}
										/>
									</StyledTableCell>
									<StyledTableCell align="left">
										{row.application_id === 9 ? row.firstname : row.firstname + " " + row.lastname}
									</StyledTableCell>
									{auth.profile === 1 && (
										<StyledTableCell align="left">
											{row.store}
										</StyledTableCell>
									)}
									<StyledTableCell align="left">
										{row.date + " " + row.hour}
									</StyledTableCell>
									<StyledTableCell align="left">
										{row.amount}
									</StyledTableCell>
									<StyledTableCell align="left">
										{row.statusJusto === "done" ? (
											<Chip
												style={{
													background:
														theme.palette.success
															.main,
													color: "#fff",
												}}
												icon={
													<CheckIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Justo"
											/>
										) : row.pya_response?.success ? (
											<Chip
												style={{
													background:
														theme.palette.success
															.main,
													color: "#fff",
												}}
												icon={
													<CheckIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Pedidos Ya"
											/>
										) :
										row.statusUber === "success" ? (
											<Chip
												style={{
													background:
														theme.palette.success
															.main,
													color: "#fff",
												}}
												icon={
													<CheckIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Uber Direct"
											/>
										) : !!row.captain_response ? (
											<Chip
												style={{
													background:
														theme.palette.success
															.main,
													color: "#fff",
												}}
												icon={
													<CheckIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Captain.AI"
											/>
										) : row.statusUber === ERROR ? (
											<Chip
												style={{
													background:
														theme.palette.error
														.main,
													color: "#fff",
												}}
												icon={
													<WarningIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Uber Direct"
											/>
										) : row.pya_response?.error ? (
											<Chip
												style={{
													background:
														theme.palette.error
														.main,
													color: "#fff",
												}}
												icon={
													<WarningIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Pedidos Ya"
											/>
										) : row.statusJusto  === ERROR ? (
											<Chip
												style={{
													background:
														theme.palette.error
														.main,
													color: "#fff",
												}}
												icon={
													<WarningIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Justo"
											/>
										) : row.enable_captain_delivery && !row.captain_response ? (
											<Chip
												style={{
													background:
														theme.palette.error
														.main,
													color: "#fff",
												}}
												icon={
													<WarningIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Captain"
											/>
										) :
										null }
										
										{row.status_order_id === 9 ? (
											<Chip
												style={{
													background:
														theme.palette.success
															.main,
													color: "#fff",
												}}
												icon={
													<CheckIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Procesada"
											/>
										) : row.status_order_id === 13 ? (
											<Chip
												style={{
													background:
														theme.palette.warning
															.main,
													color: "#fff",
												}}
												icon={
													<WarningIcon
														style={{
															color: "#fff",
														}}
													/>
												}
												label="Cancelada"
											/>
										)	: (
											<Button
												startIcon={<WarningIcon />}
												variant="contained"
												size="small"
												disabled={orders.blockResend}
												onClick={() =>
													options({
														action: "RESEND",
														data: row,
													})
												}
												style={{
													background:
														!orders.blockResend &&
														theme.palette.error
															.main,
													color: "#fff",
												}}
											>
												reintentar
											</Button>
										)}
									</StyledTableCell>
									<StyledTableCell align="right">
										<ButtonGroup
											size="small"
											fullWidth
											variant="text"
										>
											{/* 					{row.status === 0 && (
												<Fragment>
													{false ? (
														<Button
															variant="contained"
															color="primary"
															disabled
														>
															<Tooltip title="Marcar orden como leída">
																<CheckIcon />
															</Tooltip>
														</Button>
													) : (
														<Button
															variant="text"
															color="primary"
															disabled
														>
															<Tooltip title="Orden leída">
																<DobleCheckIcon />
															</Tooltip>
														</Button>
													)}
												</Fragment>
											)} */}
											<Button
												onClick={() =>
													options({
														action: "VIEW",
														data: row,
													})
												}
												color="primary"
											>
												<VisibilityIcon />
											</Button>
										</ButtonGroup>
									</StyledTableCell>
									<StyledTableCell align="left">
										{row.enable_justo_delivery && (
											<Tooltip title="Justo">
												<img
													src={JustoImage}
													alt="Justo logo"
													width="30"
												/>
											</Tooltip>
										)}

										{row.enable_pya_delivery && (
											<Tooltip title="Pedidos Ya">
												<img
													src={PyaImage}
													alt="Pedidos Ya logo"
													width="30"
												/>
											</Tooltip>
										)}
										{row.enable_uber_delivery && (
											<Tooltip title="Uber Direct">
												<img
													src={UberImage}
													alt="Uber Direct logo"
													width="30"
												/>
											</Tooltip>
										)}
									</StyledTableCell>
								</TableRow>
							))}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>

			<Dialog
				open={openModal}
				onClose={() => handleOpenCloseModal(false)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Despacho Masivo
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Por favor seleccione el servicio de delivery disponible.
					</DialogContentText>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						defaultValue={deliverySelected}
						onChange={handleDeliverySelected}
						style={{ width: "100%" }}
					>
						{deliveries.map((delivery) => (
							<MenuItem value={delivery.value}>
								{delivery.name}
							</MenuItem>
						))}
						{/* 		<MenuItem value={10}>Ten</MenuItem>
						<MenuItem value={20}>Twenty</MenuItem>
						<MenuItem value={30}>Thirty</MenuItem> */}
					</Select>

					{/* 	<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Email Address"
						type="email"
						fullWidth
					/> */}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleOpenCloseModal(false)}
						color="primary"
					>
						Cancelar
					</Button>
					<Button
						onClick={() => handleSendMassive()}
						color="primary"
						disabled={sendingMassive || !deliverySelected}
					>
						{sendingMassive && <CircularProgress size={20} />}
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
