import axios from "axios";
import appActions from "./app.actions";

const loginStatus = (status) => {
  return (dispatch) => {
    dispatch({
      type: "AUTH_SET_LOGIN_STATUS",
      payload: status,
    });
  };
};

const getStore = () => {
  return async (dispatch, getState) => {
    try {
      const auth = await getState().auth;
      if (auth.profile === 2) {
        const response = await axios.get("/store/" + auth.store);
        dispatch({ type: "AUTH_SET_STORE_DATA", payload: response.data });
      } else {
        return;
      }
    } catch (err) {
      dispatch(logoutIfNotAuth());
    }
  };
};

const login = ({ username, password }) => {
  return async (dispatch) => {
    try {
      dispatch(loginStatus("LOADING"));
      dispatch(appActions.setLoading(true));
      let response = await axios.post("/login", { username, password });

      if (!response.data.token) {
        dispatch({ type: "AUTH_LOGIN_FAIL" });
        dispatch(loginStatus("ERROR"));
        return;
      }

      const payload = {
        profile: response.data.profile_id,
        store: response.data.store_id,
        username: response.data.username,
        firstname: response.data.firstname,
        loged: "LOGED",
      };

      window.localStorage.setItem("token", response.data.token);
      axios.defaults.headers.common.authorization = `${response.data.token}`;

      dispatch({ type: "AUTH_LOGIN_SUCCESS", payload });
      dispatch(loginStatus("WELCOME"));
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(loginStatus("ERROR"));
      dispatch(appActions.setLoading(false));
    }
  };
};

const verifyToken = () => {
  return async (dispatch) => {
    try {
      await axios.post("login/validate");
      dispatch({ type: "AUTH_LOGIN_SUCCESS" });
    } catch (err) {
      dispatch(logout());
    }
  };
};

const logout = () => {
  return async (dispatch) => {
    axios.defaults.headers.common.authorization = null;
    window.localStorage.removeItem("token");
    dispatch(appActions.setMenuAnchorEl(null));
    dispatch({ type: "AUTH_LOGOUT" });
    dispatch({ type: "NOTIFIES_RESET_STATE" });
    dispatch({ type: "ORDERS_RESET_STATE" });
    dispatch({ type: "SOCKET_RESET_STATE" });
    dispatch({ type: "STORES_RESET_STATE" });
    dispatch({ type: "UTILS_RESET_STATE" });
  };
};

const logoutIfNotAuth = (err) => {
  return (dispatch) => {
    if (err?.response?.status === 403 || err?.response?.status === 401) {
      dispatch(logout);
    }
  };
};

export default {
  loginStatus,
  login,
  logout,
  verifyToken,
  logoutIfNotAuth,
  getStore,
};
