import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import { useSelector } from "react-redux";
import CardItem from "./Card";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

export default function Index() {
  const classes = useStyles();
  const menu = useSelector((state) => state.menu);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (menu.articles.data) {
      setRows(menu.articles.data);
    }
  }, [menu.articles.data]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container justify="flex-end">
          <Grid item style={{ margin: 16 }}>
            <Pagination
              count={Math.round(rows.length / 12) + 1}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={(e, p) => setPage(p)}
            />
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {menu.articles.data.slice(page, (page + 1) * 10).map((article, i) => (
            <Grid key={"article_" + i} item xs={4} lg={3}>
              <CardItem {...article} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  );
}
