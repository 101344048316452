import notifySound from "../../resources/audio/notify.wav";

const setIsMobile = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SET_IS_MOBILE", payload: value });
  };
};
const switchMenu = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SWITCH_MENU", payload: value });
  };
};
const setTheme = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SET_THEME", payload: value });
  };
};
const setLoading = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SET_LOADING", payload: value });
  };
};
const setActiveAudio = (value) => {
  return (dispatch) => {
    if (value) {
      let sound = new Audio(notifySound);
      sound.play();
    }
    dispatch({ type: "APP_SET_ACTIVEAUDIO", payload: value });
  };
};
const setMenuAnchorEl = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SET_MENUANCHOREL", payload: value });
  };
};
const setSocketConnectionState = (value) => {
  return (dispatch) => {
    dispatch({ type: "APP_SET_SOCKET_CONNECTION_STATE", payload: value });
  };
};

export default {
  setIsMobile,
  switchMenu,
  setTheme,
  setLoading,
  setActiveAudio,
  setMenuAnchorEl,
  setSocketConnectionState
};
