import React from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
export default function StateCard({ title, icon, value, loading }) {
  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ marginBottom: -20, height: "100%" }}>
        <Grid
          container
          alignItems="center"
          style={{ height: "100%" }}
          spacing={2}
        >
          <Grid item xs={3} md={4} align="center">
            {icon}
          </Grid>
          <Grid item xs={9} md={8}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  align="center"
                  color="textSecondary"
                >
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <strong>{title}</strong>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  {loading ? <Skeleton animation="wave" /> : value}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
