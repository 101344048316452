import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Grid,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  ButtonGroup,
  makeStyles,
  withStyles,
  useTheme,
  Chip,
} from "@material-ui/core";
import { Check as CheckIcon, Warning as WarningIcon } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import storesActions from "../../../redux/actions/stores.actions";
import { Pagination } from "@material-ui/lab";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "store",
    numeric: false,
    disablePadding: false,
    label: "Sucursal",
  },
  {
    id: "dateValidation",
    numeric: false,
    disablePadding: false,
    label: "Fecha validación",
  },
  {
    id: "statusValidation",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "dateInserted",
    numeric: false,
    disablePadding: false,
    label: "Fecha inserción",
  },
  {
    id: "statusInserted",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "options",
    numeric: false,
    disablePadding: true,
    label: "Opciones",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttonInfo: {
    color: "#FFF",
    background: theme.palette.info.main,
  },
  buttonWarning: {
    color: "#FFF",
    background: theme.palette.warning.main,
  },
  buttonSuccess: {
    color: "#FFF",
    background: theme.palette.success.main,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const stores = useSelector((state) => state.stores);
  const theme = useTheme();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("errors");
  const [dense] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (stores.data) {
      let aux = stores.data.map((row) => {
        let errors = 0;
        if (row.statusValidation !== "Success") {
          errors++;
        }
        if (row.statusInserted !== "Success") {
          errors++;
        }
        return {
          ...row,
          errors,
        };
      });
      setRows(aux);
    }
  }, [stores.data]);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const emptyRows = stores.query.rows - rows.length;

  const options = ({ action, data }) => {
    if (action === "VIEW_ERROR") {
      dispatch(storesActions.setDataModal(data));
      dispatch(storesActions.setViewError(true));
    }
    if (action === "VALIDATE") {
      dispatch(storesActions.validate(data));
    }
    if (action === "SENDPOS") {
      dispatch(storesActions.sendPOS(data));
    }
    if (action === "ALL") {
      dispatch(storesActions.sendALL(data));
    }
  };

  const background = (row) => {
    if (
      row.statusValidation !== "Success" ||
      row.statusInserted !== "Success"
    ) {
      return theme.palette.errorSoft.main;
    } else {
      return theme.palette.background.paper;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container justify="flex-end">
          <Grid item style={{ margin: 16 }}>
            <Pagination
              count={Math.ceil(rows.length / 30)}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={(e, p) => setPage(p)}
              color="primary"
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="Sucursales"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(30 * (page - 1), 30 * page)
                .map((row, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    style={{ background: background(row) }}
                  >
                    <StyledTableCell align="left">{row.store}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.dateValidation}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.statusValidation === "Success" ? (
                        <Chip
                          style={{
                            background: theme.palette.success.main,
                            color: "#fff",
                          }}
                          icon={<CheckIcon style={{ color: "#fff" }} />}
                          label="Éxito"
                        />
                      ) : (
                        <Button
                          startIcon={<WarningIcon />}
                          variant="contained"
                          size="small"
                          onClick={() =>
                            options({ action: "VIEW_ERROR", data: row })
                          }
                          style={{
                            background: theme.palette.error.main,
                            color: "#fff",
                          }}
                        >
                          error
                        </Button>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.dateInserted}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.statusInserted === "Success" ? (
                        <Chip
                          style={{
                            background: theme.palette.success.main,
                            color: "#fff",
                          }}
                          icon={<CheckIcon style={{ color: "#fff" }} />}
                          label="Éxito"
                        />
                      ) : (
                        <Button
                          startIcon={<WarningIcon />}
                          variant="contained"
                          size="small"
                          onClick={() =>
                            options({ action: "VIEW_ERROR", data: row })
                          }
                          style={{
                            background: theme.palette.error.main,
                            color: "#fff",
                          }}
                        >
                          error
                        </Button>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <ButtonGroup size="small" variant="contained">
                        <Button
                          className={classes.buttonInfo}
                          onClick={() =>
                            options({ action: "VALIDATE", data: row })
                          }
                        >
                          Validar
                        </Button>
                        <Button
                          className={classes.buttonInfo}
                          onClick={() =>
                            options({ action: "SENDPOS", data: row })
                          }
                        >
                          Enviar_POS
                        </Button>
                        <Button
                          className={classes.buttonInfo}
                          onClick={() => options({ action: "ALL", data: row })}
                        >
                          Ambos
                        </Button>
                      </ButtonGroup>
                    </StyledTableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
