import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	Grid,
	TableRow,
	TableSortLabel,
	Paper,
	Button,
	makeStyles,
	withStyles,
	Radio,
} from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import {
	Edit as EditIcon,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import storesActions from "../../../redux/actions/stores.actions";
import { Pagination } from "@material-ui/lab";

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const GreenRadio = withStyles({
	root: {
		color: green[400],
		"&$checked": {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	console.log(stabilizedThis);
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: "store",
		numeric: false,
		disablePadding: false,
		label: "Sucursal",
	},
	{
		id: "justoStoreId",
		numeric: false,
		disablePadding: false,
		label: "Justo ID",
	},
	{
		id: "justoDeliveryActive",
		numeric: false,
		disablePadding: false,
		label: "Justo Delivery",
	},
	{
		id: "pyaDeliveryActive",
		numeric: false,
		disablePadding: false,
		label: "Pedidos Ya Delivery",
	},
	{
		id: "uberDeliveryActive",
		numeric: false,
		disablePadding: false,
		label: "Uber Direct Delivery",
	},
	{
		id: "opciones",
		numeric: false,
		disablePadding: false,
		label: "Opciones",
	},
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							<strong>{headCell.label}</strong>
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc"
										? "sorted descending"
										: "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	buttonInfo: {
		color: "#FFF",
		background: theme.palette.info.main,
	},
	buttonWarning: {
		color: "#FFF",
		background: theme.palette.warning.main,
	},
	buttonSuccess: {
		color: "#FFF",
		background: theme.palette.success.main,
	},
}));

export default function EnhancedTable() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const stores = useSelector((state) => state.stores);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("store");
	const [dense] = useState(false);
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (stores.dataJusto) {
			console.log(stores.dataJusto);
			setRows(stores.dataJusto);
		}
	}, [stores.dataJusto]);

	const handleRequestSort = (_, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const emptyRows = stores.query.rows - rows.length;

	const onClick = (row, action) => {
		if (action === "EDIT") {
			dispatch(storesActions.getStore(row));
			history.push(`/stores-justo/edit/${row.melt_store}`);
		}
	};

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid container justify="flex-end">
					<Grid item style={{ margin: 16 }}>
						<Pagination
							count={Math.ceil(rows.length / 30)}
							page={page}
							variant="outlined"
							shape="rounded"
							onChange={(e, p) => setPage(p)}
							color="primary"
						/>
					</Grid>
				</Grid>
				<TableContainer>
					<Table
						className={classes.table}
						size="small"
						aria-labelledby="Sucursales"
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{stableSort(rows, getComparator(order, orderBy))
								.slice(30 * (page - 1), 30 * page)
								.map((row, index) => (
									<TableRow
										hover
										tabIndex={-1}
										key={row.id}
										// style={{ background: background(row) }}
									>
										<StyledTableCell align="left">
											{row.store}
										</StyledTableCell>
										<StyledTableCell align="left">
											{row.storeId}
										</StyledTableCell>
										<StyledTableCell align="left">
											{/* <Switch
												checked={row.isDeliveryJusto}
												color="primary"
												disabled={true}
												inputProps={{
													"aria-label":
														"primary checkbox",
												}}
											/> */}
											<GreenRadio
												checked={row.isDeliveryJusto}
												// onChange={handleChange}
												// value="c"
												name="radio-button"
												inputProps={{
													"aria-label": "C",
												}}
											/>
										</StyledTableCell>

										<StyledTableCell align="left">
											<GreenRadio
												checked={row.is_pya_delivery}
												// onChange={handleChange}
												// value="c"
												name="radio-button"
												inputProps={{
													"aria-label": "C",
												}}
											/>
										</StyledTableCell>
										<StyledTableCell align="left">
											<GreenRadio
												checked={row.is_uber_delivery}
												name="radio-button"
												inputProps={{
													"aria-label": "C",
												}}
											/>
										</StyledTableCell>
										<StyledTableCell align="left">
											<Button
												// className={classes.buttonInfo}
												onClick={() =>
													onClick(row, "EDIT")
												}
											>
												<EditIcon />
											</Button>
										</StyledTableCell>
									</TableRow>
								))}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
}
