import React, { useEffect, useCallback } from "react";
import { Paper, Card, CardContent, makeStyles } from "@material-ui/core";
import HeaderPage from "../../Frames/HeaderPage";
import HeaderContent from "./HeaderContent";
import Search from "./Search";
import Table from "./Table";
import ViewModal from "./ViewModal";
import { useDispatch, useSelector } from "react-redux";
import ordersActions from "../../../redux/actions/orders.actions";
import utilsActions from "../../../redux/actions/utils.actions";
import NewOrderModal from "./NewOrderModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);

  const fetchOrders = useCallback(() => {
    dispatch(ordersActions.fetch());
    dispatch(utilsActions.fetchAggregators());
  }, [dispatch]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <Paper className={classes.root}>
		{console.log(process.env.REACT_APP_LOGO_WEB)}
      <Card>
        <CardContent>
          <ViewModal />
          <HeaderPage content={<HeaderContent />} />
          <Search />
          <Table />
          {orders.newOrdersModal && <NewOrderModal />}
        </CardContent>
      </Card>
    </Paper>
  );
}
