import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import StoreIcon from "@material-ui/icons/Store";
import translatePaymentMethod from "./../../../../resources/utils/translatePaymentMethod";
import { ECOMMERCE_ID } from "../../../../constants";
export default function General({ data }) {
  const utils = useSelector((state) => state.utils);

  return (
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Typography variant="h5">General</Typography>
        <hr />
      </Grid>
      {(data.application_id === 3 || data.application_id === 6) && <Grid item xs={4} align="center">
        <img
          src={utils.appLogos[data.application_id]}
          alt="logo"
          style={{ width: "90%" , marginTop: 6}}
        />
      </Grid>}
      {data.application_id === 10 && 
      <Grid item xs={4} align="center">
        <img
          src={utils.appLogos[3]}
          alt="logo"
          style={{ width: "90%" , marginTop: 6}}
        />
      </Grid>}
      {data.application_id === 1 && <Grid item xs={4} align="center">
        <img
          src={utils.appLogos[data.application_id]}
          alt="logo"
          style={{ width: "90%", marginTop: -20}}
        />
      </Grid>}
      {data.application_id === 4 && <Grid item xs={4} align="center">
        <img
          src={utils.appLogos[data.application_id]}
          alt="logo"
          style={{ width: "90%", marginTop: -20}}
        />
      </Grid>}
      {(data.application_id === 5 || data.application_id === 7) && <Grid item xs={4} align="center">
        <img
          src={utils.appLogos[data.application_id]}
          alt="logo"
          style={{ width: "90%", marginTop: -20}}
        />
      </Grid>}
      {(data.application_id === 8 || data.application_id === 9) && <Grid item xs={4} align="center" className="d-flex justify-content-center align-items-center">
        <img
          src={utils.appLogos[data.application_id]}
          alt="logo"
          style={{ width: "90%" }}
        />
      </Grid>}
      <Grid item xs={8}>
        {(ECOMMERCE_ID.includes(data.application_id)) &&
          (data.delivery ? (
            <Typography variant="h6" color="textPrimary">
              <MotorcycleIcon style={{ marginBottom: 6, fontSize: 24 }} />{" "}
              Delivery
            </Typography>
          ) : (
            <Typography variant="h6" color="textPrimary">
              <StoreIcon style={{ marginBottom: 6, fontSize: 24 }} />{" "}
              Retiro en tienda
            </Typography>
          ))}
        {(data.application_id === 8 || data.application_id === 9) && (
          <Typography variant="h6" color="textPrimary">
            <MotorcycleIcon style={{ marginBottom: 6, fontSize: 24 }} />{" "}
            Delivery
          </Typography>)}
        <Typography variant="body1" color="textPrimary">
          Sucursal: {data.store}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Pago: {translatePaymentMethod(data.deliveryType)}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Fecha: {data.date + " " + data.hour}
        </Typography>
        {(ECOMMERCE_ID.includes(data.application_id)) && (
          <Typography variant="body1">Propina: ${data.tip}</Typography>
        )}
        <Typography variant="h6" color="textPrimary">
          <strong>Monto: {data.amount}</strong>
        </Typography>
        {(ECOMMERCE_ID.includes(data.application_id)) && <hr />}
      </Grid>
    </Grid>
  );
}
