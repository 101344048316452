import React, { Fragment, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../../redux/actions/menu.actions";
import ViewRows from "./ViewRows";
import CreateEdit from "./CreateEdit";

export default function Index() {
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const init = useCallback(() => {
    dispatch(menuActions.setArticlesView("VIEW"));
    dispatch(menuActions.fetchArticles());
    dispatch(menuActions.fetchCategories());
  }, [dispatch]);

  useEffect(() => init(), [init]);

  return (
    <Fragment>
      {menu.articles.view === "VIEW" && <ViewRows />}
      {menu.articles.view === "CREATE" && <CreateEdit />}
    </Fragment>
  );
}
