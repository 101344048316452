import React, { useEffect, useState } from "react";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Edit as EditIcon, Add as AddIcon } from "@material-ui/icons";
import Article from "./Article";
import { useSelector } from "react-redux";

export default function Category({ name, articles }) {
  const menu = useSelector((state) => state.menu);

  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    if (menu.resume.expand === "EXPAND") {
      setExpanded(true);
    }
    if (menu.resume.expand === "DECREASE") {
      setExpanded(false);
    }
  }, [menu.resume.expand]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      style={{ margin: 0 }}
      elevation={2}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Button
              color="primary"
              size="small"
              onClick={(event) => handleClick(event)}
              onFocus={(event) => event.stopPropagation()}
            >
              <MoreVertIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={(event) => handleClose(event)}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Editar categoría</ListItemText>
              </MenuItem>
              <MenuItem onClick={(event) => handleClose(event)}>
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText> Añadir artículos a la categoría</ListItemText>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <strong>{name}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Artículos: {articles?.length}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          {articles?.map((article, i) => (
            <Grid item xs={12} key={article.name + i}>
              <Article {...article} menu={name} />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
