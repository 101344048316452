import React from "react";
import {
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  Card,
  CardContent,
  useTheme,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";

export default function Product({ name, price, menu }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <Card
      style={{
        width: "100%",
        border: "solid 1px " + theme.palette.primary.main,
        margin: 0,
      }}
    >
      <CardContent style={{ padding: 8, margin: 0 }}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Button
              color="primary"
              size="small"
              onClick={(event) => handleClick(event)}
              onFocus={(event) => event.stopPropagation()}
            >
              <MoreVertIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={(event) => handleClose(event)}>
                <ListItemIcon>
                  <RemoveIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Marcar artículo como agotado</ListItemText>
              </MenuItem>
              <MenuItem onClick={(event) => handleClose(event)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText>Eliminar artículo de la categoría</ListItemText>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item>
            <Link to={"/menu/item/" + menu + "/" + name}>
              <Typography variant="body1">{name}</Typography>{" "}
            </Link>
          </Grid>
          <Grid item>
            <Link to={"/menu/item/" + menu + "/" + name}>
              <Typography variant="body2" color="textSecondary">
                {price}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
