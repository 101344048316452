import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export default function Header({ content }) {
  const auth = useSelector((state) => state.auth);
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <img
              src={process.env.REACT_APP_LOGO_WEB}
              style={{ height: 60 }}
              alt="logo empresa"
            />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h5">
                  {auth.profile === 1 && "Administrador general"}
                  {auth.profile === 2 &&
                    "Sucursal: " + auth.storeData.description}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {auth.firstname} ({auth.username})
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{content}</Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  content: PropTypes.element,
};
