import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "../Login";

export default function AuthRoute({ component: Component, roles, ...rest }) {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        // No está logeado
        if (auth.loged === "NOT_LOGED") {
          return <Login />;
        }
        // Fix mientras redux actualiza el estado
        if (!auth.profile) {
          return null;
        }

        // No tiene permisos para ejecutar esa ruta
        if (roles && roles.indexOf(auth.profile) === -1) {
          return <Redirect to={{ pathname: "/" }} />;
        }

        // Devolver el componente de la ruta
        return <Component {...props} />;
      }}
    />
  );
}
