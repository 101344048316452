import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  DialogActions,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../../../redux/actions/menu.actions";
import translateSched from "../../../../../../resources/utils/translateSched";

export default function ConfirmCreate({ formData }) {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);

  const [data, setData] = useState({
    schedules: [],
    schedulesHuman: [],
    name: "",
  });

  useEffect(() => {
    if (formData) {
      let temp = formData.schedules.map((element) => {
        return {
          ...element,
          hours: element.humanHours,
        };
      });
      setData({
        schedulesHuman: translateSched(temp),
        schedules: temp,
        name: formData.name,
      });
    }
  }, [formData, menu.menus.createModal]);

  const handleCancel = () => {
    dispatch(menuActions.setMenusCreateModal(false));
  };

  const handleCreate = () => {
    dispatch(menuActions.setMenusCreateModal(false));
    dispatch(menuActions.setMenusView("VIEW"));
    dispatch(menuActions.createMenu(data));
  };

  return (
    <Dialog open={menu.menus.createModal}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Crear menú {data.name}</Typography>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              ¿Estás seguro de que deseas crear este menú?
            </Typography>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Horario:</Typography>
            {data.schedulesHuman.map((element, key) => (
              <Typography key={"dia_" + key} variant="body1">
                {element}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" color="primary">
          <Button
            variant="outlined"
            onClick={() => handleCancel()}
            startIcon={<Close />}
          >
            No, salir
          </Button>
          <Button
            variant="contained"
            onClick={() => handleCreate()}
            startIcon={<Check />}
          >
            Sí, crear
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
