import React from "react";
import { Switch } from "react-router-dom";
import Orders from "../Pages/Orders";
import Stores from "../Pages/Stores";
import StoresJusto from "../Pages/StoresJusto";
import StoresJustoForm from "../Pages/StoresJusto/Form/index";
import Dashboard from "../Pages/Dashboard";
import Menu from "../Pages/Menu";
import ViewItem from "../Pages/Menu/ViewItem";
import AuthRoute from "./AuthRoute";
import AuthRedirect from "./AuthRedirect";

export default function Routes() {
  return (
    <Switch>
      {/* <Route path="/orders_temp" component={Orders} /> */}
      <AuthRoute path="/" exact roles={[1, 2]} component={Dashboard} />
      <AuthRoute path="/orders" roles={[1, 2]} component={Orders} />
      <AuthRoute path="/menu" roles={[1]} component={Menu} />
      <AuthRoute path="/stores" roles={[1]} component={Stores} />
      <AuthRoute path="/menu/item/:id/:id" roles={[1]} component={ViewItem} />
      <AuthRoute path="/stores-justo" exact roles={[1]} component={StoresJusto} />
      <AuthRoute path="/stores-justo/edit/:id" exact roles={[1]} component={StoresJustoForm} />
      <AuthRedirect />
    </Switch>
  );
}
