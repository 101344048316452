import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  useTheme,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import Category from "./Category";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../../redux/actions/menu.actions";
import translateSched from "../../../../../resources/utils/translateSched";

export default function Index() {
  const theme = useTheme();
  const menu = useSelector((state) => state.menu);

  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("EMPTY");
  const [scheds, setScheds] = useState([]);
  const [readMore, setReadMore] = useState(false);

  const fetchMenus = useCallback(() => {
    dispatch(menuActions.fetchMenus());
  }, [dispatch]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  useEffect(() => {
    setReadMore(false);
    if (selectedMenu !== "EMPTY") {
      let data = menu.menus.data.find(
        (element) => element.name === selectedMenu
      );
      setScheds(translateSched(data.schedules));
    } else {
      setScheds([]);
    }
  }, [selectedMenu, menu.menus.data]);

  return (
    <Fragment>
      <Grid container spacing={2} style={{ width: "100%" }} alignItems="center">
        <Grid item xs={3}>
          <TextField
            label="Menús"
            name="menu"
            value={selectedMenu}
            onChange={(e) => setSelectedMenu(e.target.value)}
            variant="outlined"
            margin="dense"
            select
            fullWidth
          >
            <MenuItem value="EMPTY">
              <em>Selecciona un menú</em>
            </MenuItem>
            {menu.menus.data.map((menuItem) => (
              <MenuItem key={menuItem.name} value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          {Boolean(scheds.length) && (
            <Typography variant="body1" onClick={() => setReadMore(!readMore)}>
              Horario del menú{"   "}
              {Boolean(scheds.length > 1) && (
                <strong style={{ color: theme.palette.success.main }}>
                  {readMore ? "(Menos)" : "(Más)"}
                </strong>
              )}
            </Typography>
          )}
          {scheds.slice(0, readMore ? scheds.length : 1).map((element, i) => (
            <Typography
              variant="body2"
              color="textSecondary"
              key={"sched_" + i}
            >
              {element}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={5} align="right">
          <ButtonGroup>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => dispatch(menuActions.setResumeExpand("DECREASE"))}
            >
              Contraer
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => dispatch(menuActions.setResumeExpand("EXPAND"))}
            >
              Expandir
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<Check />}
            >
              Guardar
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {menu.menus.data
          .find((element) => element.name === selectedMenu)
          ?.categories?.map((category, i) => (
            <Grid item xs={6} key={"category_" + i}>
              <Category {...category} />
            </Grid>
          ))}
      </Grid>
    </Fragment>
  );
}
