const addBadge = (name) => {
	return (dispatch) => {
		dispatch({
			type: "NOTIFY_ADD_BADGET",
			payload: name,
		});
	};
};

const resetBadge = (name) => {
	return (dispatch) => {
		dispatch({
			type: "NOTIFY_RESET_BADGET",
			payload: name,
		});
	};
};

const enqueueNotify = (type, customText = null) => {
	return (dispatch) => {
		switch (type) {
			case "ORDERS_RESEND_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Órden reenviada con éxito",
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_RESEND_WARNING": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "No se pudo reinsertar la orden",
						severity: "warning",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_RESEND_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "No se pudo reenviar la orden",
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "STORES_VALIDATE_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Validación realizada con éxito",
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}
			case "STORES_VALIDATE_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "No se pudo realizar la validación",
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "STORES_SENDPOS_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Inserción POS realizada con éxito",
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}
			case "STORES_SENDPOS_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "No se pudo realizar la inserción POS",
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "STORES_SENDALL_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Validación e inserción POS realizadas con éxito",
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}
			case "STORES_SENDALL_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text:
							"No se pudo realizar la validación e inserción POS",
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_ADD_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						severity: "addOrder",
						type: "sound",
					},
				});
				return true;
			}
			case "ORDERS_ADD_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Orden con problemas detectada",
						severity: "warning",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_ADD_CANCEL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Orden cancelada",
						severity: "warning",
						type: "snackbar",
					},
				});
				return true;
			}
			case "MENU_CREATE_MENU_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Menú creado con éxito",
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}
			case "MENU_CREATE_MENU_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "No se pudo crear el menú",
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_SEND_JUSTO_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Órden integrada con JUSTO con éxito",
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_SEND_PYA_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Órden integrada con Pedidos Ya con éxito",
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_SEND_UBER_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Órden integrada a UBER con éxito",
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}

			case "MASSIVE_ORDERS_SEND_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Despacho masivo realizado con exito",
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}
			case "MASSIVE_ORDERS_SEND_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: `Error al realizar despacho masivo. ${customText}`,
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_SEND_PYA_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: `Error al integrar la orden con Pedidos Ya. ${customText}`,
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_SEND_PYA_FAIL_BEETRACK": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: `${customText}`,
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_SEND_JUSTO_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: `Error al integrar la orden con JUSTO. ${customText}`,
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_SEND_JUSTO_FAIL_BEETRACK": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: `${customText}`,
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_SEND_UBER_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: `Error al integrar la orden con UBER. ${customText}`,
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "ORDERS_SEND_UBER_FAIL_BEETRACK": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: `${customText}`,
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			case "STORES_EDIT_SUCCESS": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: !customText
							? "Local editado con éxito"
							: `Local ${customText} editado con éxito`,
						severity: "success",
						type: "snackbar",
					},
				});
				return true;
			}
			case "STORES_EDIT_FAIL": {
				dispatch({
					type: "NOTIFY_ENQUEUE",
					payload: {
						duration: 3000,
						text: "Error al editar el local",
						severity: "error",
						type: "snackbar",
					},
				});
				return true;
			}
			default: {
				return false;
			}
		}
	};
};

const dequeueNotify = () => {
	return (dispatch) => dispatch({ type: "NOTIFY_DEQUEUE" });
};

export default { addBadge, resetBadge, enqueueNotify, dequeueNotify };
