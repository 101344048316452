import axios from "axios";
import appActions from "./app.actions";
import authActions from "./auth.actions";
import notifiesAction from "./notifies.actions";

const cleanNum = (num) => {
	if (num < 10) return "0" + num;
	else return num + "";
};

const cleanDate = (date = "") => {
	if (date + "" === "") return "";
	return (
		cleanNum(date.getDate()) +
		"/" +
		cleanNum(date.getMonth() + 1) +
		"/" +
		date.getFullYear()
	);
};

const fetch = () => {
	return async (dispatch, getState) => {
		try {
			dispatch(appActions.setLoading(true));
			dispatch(notifiesAction.resetBadge("orders"));

			const query = await getState().orders.query;
			const auth = await getState().auth;

			let store;
			if (auth.profile === 1) {
				store = query.store;
			} else {
				store = String(auth.store);
			}

			const response = await axios.post("/orders", {
				...query,
				store,
				start: cleanDate(query.start),
				end: cleanDate(query.end),
				status: parseInt(query.status),
			});
			dispatch({
				type: "ORDERS_FETCH",
				payload: {
					data: response.data.rows,
					totalRows: response.data.count,
				},
			});
			dispatch(appActions.setLoading(false));
		} catch (err) {
			dispatch(appActions.setLoading(false));
			dispatch(authActions.logoutIfNotAuth(err));
		}
	};
};

const getOrder = ({ application_id, order_id }) => {
	return async (dispatch) => {
		try {
			console.log("ENTRA");
			dispatch(appActions.setLoading(true));

			console.log("Aplicaction ID", [application_id, order_id]);

			const response = await axios.get(
				"/orders/" + application_id + "/" + order_id
			);

			console.log("response----", response.data);

			dispatch(setDataModal(response.data));
			dispatch(setViewModal(true));
			dispatch(appActions.setLoading(false));
		} catch (err) {
			console.log(err);

			dispatch(appActions.setLoading(false));
			dispatch(authActions.logoutIfNotAuth(err));
		}
	};
};

const setQuery = (query) => {
	return (dispatch) => {
		dispatch({ type: "ORDERS_SET_QUERY", payload: { ...query, page: 1 } });
		dispatch(fetch());
	};
};

const setQueryWithoutRefresh = (query) => {
	return (dispatch) => {
		dispatch({ type: "ORDERS_SET_QUERY", payload: { ...query, page: 1 } });
	};
};

const setPage = (page) => {
	return (dispatch) => {
		dispatch({ type: "ORDERS_SET_PAGE", payload: page });
		dispatch(fetch());
	};
};

const addData = (data) => {
	return async (dispatch, getState) => {
		try {
			const orders = await getState().orders;
			let temp = orders.data;

			// Comprobar que la orden no esté en el sistema
			if (!temp.find((order) => order.order_id === data.order_id)) {
				if (data.status_order_id === 9) {
					const pathname = await getState().router.location.pathname;
					dispatch(
						notifiesAction.enqueueNotify("ORDERS_ADD_SUCCESS")
					);
					if (pathname !== "/orders") {
						dispatch(notifiesAction.addBadge("orders"));
					}
				} else if (data.status_order_id === 13) {
					dispatch(notifiesAction.enqueueNotify("ORDERS_ADD_CANCEL"));
				} else {
					dispatch(notifiesAction.enqueueNotify("ORDERS_ADD_FAIL"));
					await addTemporalNewOrders(dispatch, getState, data);
				}
				temp = [data].concat(temp);
				if (temp.length > 30) {
					temp.pop();
				}
				dispatch({
					type: "ORDERS_FETCH",
					payload: { data: temp, totalRows: orders.totalRows + 1 },
				});
			}
		} catch (err) {
			console.log(err);
		}
	};
};

const setDataModal = (data) => {
	return (dispatch) => {
		dispatch({ type: "ORDERS_SET_DATA_MODAL", payload: data });
	};
};

const setViewModal = (value) => {
	return (dispatch) => {
		dispatch({ type: "ORDERS_SET_VIEW_MODAL", payload: value });
	};
};

const resend = (order) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: "ORDERS_SET_BLOCK_RESEND", payload: true });
			await axios.post("/orders/resend", { ...order });
			const response = await axios.get(
				"/orders/" + order.application_id + "/" + order.order_id
			);
			if (response.data.status_order_id === 9) {
				let orders = await getState().orders.data;
				orders = orders.map((el) =>
					el.order_id === order.order_id ? response.data : el
				);
				dispatch({ type: "ORDERS_FETCH", payload: { data: orders } });
				dispatch(notifiesAction.enqueueNotify("ORDERS_RESEND_SUCCESS"));
			} else {
				dispatch(notifiesAction.enqueueNotify("ORDERS_RESEND_WARNING"));
			}
			setTimeout(
				() =>
					dispatch({
						type: "ORDERS_SET_BLOCK_RESEND",
						payload: false,
					}),
				1000
			);
		} catch (err) {
			dispatch(notifiesAction.enqueueNotify("ORDERS_RESEND_FAIL"));
			dispatch({ type: "ORDERS_SET_BLOCK_RESEND", payload: false });
		}
	};
};

const resetQuery = () => {
	return async (dispatch) => {
		await dispatch({ type: "ORDERS_RESET_QUERY" });
		await dispatch(fetch());
	};
};

const setCheckedOrder = (order_id, checked) => {
	console.log(order_id, checked);
	return (dispatch) => {
		dispatch({ type: "SET_CHECKED_ORDER", payload: { order_id, checked } });
	};
};

const setAllCheckedOrder = (checked) => {
	return (dispatch) => {
		dispatch({ type: "SET_ALL_CHECKED_ORDER", payload: { checked } });
	};
};

const sendJusto = (order) => {
	return async (dispatch) => {
		try {
			dispatch({ type: "ORDERS_SET_DATA_JUSTO" });
			dispatch({ type: "ORDERS_SET_BLOCK_JUSTO_SEND", payload: true });

			const response = await axios.post("/orders/justo", {
				application_id: order.application_id,
				order_id: order.order_id,
				melt_store: order.store_id,
				nOrder_melt: order.nOrder || null,
			});
			console.log(response);
			dispatch(notifiesAction.enqueueNotify("ORDERS_SEND_JUSTO_SUCCESS"));
			dispatch(getOrder(order));
			dispatch(fetch());
		} catch (err) {
			if (err?.response?.data?.beetrack) {
				dispatch(
					notifiesAction.enqueueNotify(
						"ORDERS_SEND_JUSTO_FAIL_BEETRACK",
						err.response.data.message
					)
				);
			} else {
				const message = err?.response?.data[0] ?? "";
				dispatch(
					notifiesAction.enqueueNotify(
						"ORDERS_SEND_JUSTO_FAIL",
						message
					)
				);
			}

			setTimeout(
				() =>
					dispatch({
						type: "ORDERS_SET_BLOCK_JUSTO_SEND",
						payload: false,
					}),
				1000
			);
			dispatch(getOrder(order));
		}
	};
};

const sendPya = (order) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: "ORDERS_SET_DATA_PYA" });

			const response = await axios.post("/orders/pya", {
				application_id: order.application_id,
				order_id: order.order_id,
				melt_store: order.store_id,
				nOrder_melt: order.application_id === 3 ? order.nOrder || null : order.order_id || null,
			});

			if (response.data.success) {
				dispatch(
					notifiesAction.enqueueNotify("ORDERS_SEND_PYA_SUCCESS")
				);
				dispatch(fetch());
			} else {
				dispatch(
					notifiesAction.enqueueNotify(
						"ORDERS_SEND_PYA_FAIL",
						response.data.message
					)
				);
			}
			dispatch(getOrder(order));
		} catch (err) {
			console.error(err);
			if (err?.response?.data?.beetrack) {
				dispatch(
					notifiesAction.enqueueNotify(
						"ORDERS_SEND_PYA_FAIL_BEETRACK",
						err.response.data.message
					)
				);
			} else {
				dispatch(
					notifiesAction.enqueueNotify(
						"ORDERS_SEND_PYA_FAIL",
						err.response.data.message
					)
				);
			}
		}
	};
};

const sendUber = (order) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: "ORDERS_SET_DATA_UBER" });

			const response = await axios.post("/orders/uber", {
				application_id: order.application_id,
				order_id: order.order_id,
				melt_store: order.store_id,
				nOrder_melt: order.application_id === 3 ? order.nOrder || null : order.order_id || null,
			});

			console.log({response});
			if (response.status === 200) {
				dispatch(
					notifiesAction.enqueueNotify("ORDERS_SEND_UBER_SUCCESS")
				);
				dispatch(fetch());
			} else {
				dispatch(
					notifiesAction.enqueueNotify(
						"ORDERS_SEND_UBER_FAIL",
						response.data.message
					)
				);
				dispatch(fetch());
			}
			dispatch(getOrder(order));
		} catch (err) {
			console.error(err);
			if (err?.response?.data?.beetrack) {
				dispatch(
					notifiesAction.enqueueNotify(
						"ORDERS_SEND_UBER_FAIL_BEETRACK",
						err.response.data.message
					)
				);
			} else {
				dispatch(
					notifiesAction.enqueueNotify(
						"ORDERS_SEND_UBER_FAIL",
						err.response?.data[0]?.message
					)
				);
				dispatch(fetch());
			}
			dispatch(getOrder(order));
		}
	};
};

const sendMassive = (orders, typeDelivery) => {
	return async (dispatch, getState) => {
		try {
			/* 	console.log(order); */
			dispatch({ type: "SENDING_MASSIVE_ORDERS", payload: true });
			dispatch({ type: "SENDED_MASSIVE_ORDER", payload: false });
			/* 	dispatch({ type: "ORDERS_SET_BLOCK_JUSTO_SEND", payload: true });
			console.log(order); */
			const response = await axios.post("/orders/massiveOrders", {
				orders,
				type_delivery: typeDelivery,
			});
			console.log(response);
			/* dispatch({
				type: "ORDERS_FETCH",
				payload: {
					data: response.data.rows,
					totalRows: response.data.count,
				},
			}); */
			if (response.data.success) {
				dispatch(
					notifiesAction.enqueueNotify("MASSIVE_ORDERS_SEND_SUCCESS")
				);
				dispatch({ type: "SENDED_MASSIVE_ORDER", payload: true });
				dispatch(fetch());
				/* const orders = await getState().orders;
				
				const orderIndex = orders.data.findIndex(orderState => orderState.order_id === order.order_id);
				orders.data[orderIndex].pya_response = response.data;
				console.log("HEREE",orders.data);
				dispatch({
					type: "ORDERS_FETCH",
					payload: {
						data: orders.data,
						totalRows: orders.totalRows,
					},
				}) */
			} else {
				dispatch(
					notifiesAction.enqueueNotify(
						"MASSIVE_ORDERS_SEND_FAIL",
						response.data.message
					)
				);
			}

			dispatch({ type: "SENDING_MASSIVE_ORDERS", payload: false });

			/* 	dispatch(getOrder(order)); */
			/* 	dispatch(notifiesAction.enqueueNotify("ORDERS_SEND_JUSTO_SUCCESS"));
			dispatch(getOrder(order)); */
		} catch (err) {
			console.error(err);
			dispatch({ type: "SENDING_MASSIVE_ORDERS", payload: false });

			dispatch(
				notifiesAction.enqueueNotify(
					"MASSIVE_ORDERS_SEND_FAIL",
					err.response.data.message
				)
			);
			/* 		const message = err?.response?.data[0] ?? '';
			dispatch(notifiesAction.enqueueNotify("ORDERS_SEND_JUSTO_FAIL", message));
			setTimeout(
				() =>
					dispatch({
						type: "ORDERS_SET_BLOCK_JUSTO_SEND",
						payload: false,
					}),
				1000
			);
			console.log(err.response); */
		}
	};
};

const addTemporalNewOrders = async (dispatch, getState, data) => {
	const { newOrdersModalList } = await getState().orders;
	dispatch({ type: "ORDERS_SET_NEW_ORDERS_MODAL", payload: true });
	
	if (!newOrdersModalList.length) {
		dispatch({
			type: "ORDERS_SET_NEW_ORDERS_MODAL_LIST",
			payload: [data],
		});
	} else {
		const tempData = newOrdersModalList.some((item) => item.order_id === data.order_id)
			? [...newOrdersModalList]
			: [...newOrdersModalList, data];

		dispatch({
			type: "ORDERS_SET_NEW_ORDERS_MODAL_LIST",
			payload: tempData, 
		});
	}
};

const setNewOrdersModal = (value) => {
  return (dispatch) => {
    dispatch({ type: "ORDERS_SET_NEW_ORDERS_MODAL", payload: value });
  };
};

const setNewOrdersModalList = (value) => {
  return (dispatch) => {
    dispatch({ type: "ORDERS_SET_NEW_ORDERS_MODAL_LIST", payload: value });
  };
};

export default {
	fetch,
	setQuery,
	setPage,
	resend,
	addData,
	setDataModal,
	setViewModal,
	resetQuery,
	getOrder,
	setQueryWithoutRefresh,
	sendJusto,
	sendPya,
	sendUber,
	setCheckedOrder,
	setAllCheckedOrder,
	sendMassive,
	addTemporalNewOrders,
	setNewOrdersModal,
	setNewOrdersModalList,
};
