import React from "react";
import { Typography } from "@material-ui/core";

export default function HeaderContent() {
  return (
    <Typography variant="h6" color="textSecondary">
      {new Intl.DateTimeFormat("Es-es", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date())}
    </Typography>
  );
}
