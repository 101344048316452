// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Slider,
  CardContent,
  Card,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export default function CreateMenu({
  days,
  hours,
  humanHours,
  setFormData,
  i,
  formData,
  error,
}) {
  const theme = useTheme();

  useEffect(() => {}, [hours]);

  function toMinutes(value) {
    if (value === "25") return 15;
    if (value === "5") return 30;
    if (value === "75") return 45;
  }

  const setHumanHours = (value) => {
    let init = String(value[0]);
    let finish = String(value[1]);

    let parseInit = init.split(".");
    let parseFinish = finish.split(".");

    let initOutput =
      parseInit[0] === "24"
        ? "00"
        : parseInit[0] +
          ":" +
          (parseInit.length === 2 ? toMinutes(parseInit[1]) : "00");
    let finishOutput =
      parseFinish[0] === "24"
        ? "00"
        : parseFinish[0] +
          ":" +
          (parseFinish.length === 2 ? toMinutes(parseFinish[1]) : "00");

    return [initOutput, finishOutput];
  };

  const handleSetDays = (value) => {
    let schedules = formData.schedules;
    schedules[i] = {
      ...schedules[i],
      days: value,
    };
    setFormData({ ...formData, schedules });
  };

  const handleSetHours = (value) => {
    if (value[0] === value[1] || value[0] >= value[1]) {
      return;
    }
    let temp = setHumanHours(value);

    let schedules = formData.schedules;
    schedules[i] = {
      ...schedules[i],
      hours: value,
      humanHours: temp,
    };
    setFormData({ ...formData, schedules });
  };

  const handleDismiss = () => {
    let schedules = formData.schedules.filter((_, k) => k !== i);
    setFormData({ ...formData, schedules });
  };

  return (
    <Card elevation={2}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6">Horario {i + 1}</Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="text"
              onClick={() => handleDismiss()}
            >
              <Close />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <hr />
            {error === "DAYS_ERROR" && !days.length && (
              <Typography
                variant="body2"
                style={{
                  color: error ? theme.palette.error.main : "",
                  marginBottom: 6,
                }}
                align="center"
              >
                Debes completar este horario o bien, eliminarlo.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} align="center">
            <ToggleButtonGroup
              size="small"
              value={days}
              onChange={(_, newDays) => handleSetDays(newDays)}
            >
              <ToggleButton value="1">lunes</ToggleButton>
              <ToggleButton value="2">martes</ToggleButton>
              <ToggleButton value="3">miércoles</ToggleButton>
              <ToggleButton value="4">jueves</ToggleButton>
              <ToggleButton value="5">viernes</ToggleButton>
              <ToggleButton value="6">sábado</ToggleButton>
              <ToggleButton value="0">domingo</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={10}>
            <Slider
              value={hours}
              min={0}
              max={24}
              step={0.25}
              onChange={(_event, value) => handleSetHours(value)}
              aria-labelledby="range-slider"
              disabled={!days.length}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              style={{
                color:
                  error === "SCHEDULE_ERROR" ? theme.palette.error.main : "",
              }}
            >
              de {humanHours[0]} a {humanHours[1]}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
