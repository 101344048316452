import { createMuiTheme } from "@material-ui/core/styles";

const primary = "#1D1D1D";
const secondary = "#AEB6BF";
const success = "#00C851";
const warning = "#FF8000";
const info = "#0080B3";
const errorSoft = "#F9A19A";
const infoSoft = "#90CAF9";
const warningSoft = "#FFCC99";
export default createMuiTheme({
  palette: {
    type: "light",

    primary: {
      main: info,
    },
    secondary: {
      main: secondary,
    },
    success: {
      main: success,
    },
    warning: {
      main: warning,
    },
    info: {
      main: info,
    },
    errorSoft: {
      main: errorSoft,
    },
    infoSoft: {
      main: infoSoft,
    },
    warningSoft: {
      main: warningSoft,
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: primary,
      },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: secondary,
          color: "#000",
        },
        "&$selected": {
          background: info,
          color: "#fff",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 3,
      },
    },
    MuiPaper: {
      root: {
        marginBottom: "4%",
      },
    },
  },
});
