import React, { Fragment } from "react";
import SearchAdmin from "./SearchAdmin";
import SearchStore from "./SearchStore";
import SearchSuperAdmin from "./SearchSuperAdmin";
import { useSelector } from "react-redux";

export default function Index() {
  const auth = useSelector((state) => state.auth);

  return (
    <Fragment>
      {auth.profile === 0 && <SearchAdmin />}
      {auth.profile === 2 && <SearchStore />}
      {auth.profile === 1 && <SearchSuperAdmin />}
    </Fragment>
  );
}
