import React, { Fragment, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../../redux/actions/menu.actions";
import View from "./View";
import Create from "./Create";

export default function Index() {
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const init = useCallback(() => {
    dispatch(menuActions.setCategoriesView("VIEW"));
  }, [dispatch]);

  useEffect(() => init(), [init]);

  return (
    <Fragment>
      {menu.categories.view === "VIEW" && <View />}
      {menu.categories.view === "CREATE" && <Create />}
    </Fragment>
  );
}
