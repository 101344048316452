import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ordersActions from "../../../../redux/actions/orders.actions";
import Header from "../../../Frames/HeaderModal";
import Content from "./Content";

export default function Index() {
	const dispatch = useDispatch();
	const orders = useSelector((state) => state.orders);
	const [data, setData] = useState({});

	useEffect(() => {
		if (orders.dataModal) {
			setData(orders.dataModal);
		}
	}, [orders.dataModal]);

	const handleClose = () => {
		dispatch(ordersActions.setViewModal(false));
		dispatch(ordersActions.setDataModal(null));
	};

	return (
		<Dialog
			onClose={handleClose}
			open={orders.viewModal}
			maxWidth="md"
			scroll="body"
		>
			<DialogContent>
				<Header
					handleClose={handleClose}
					title={
						data.application_id === 9
							? "Orden " + data.firstname
							: "Orden " + data.firstname + " " + data.lastname
					}
				/>
				<Content data={data} />
				<br />
			</DialogContent>
		</Dialog>
	);
}
