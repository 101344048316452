import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  Tooltip,
  Grid,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

export default function Content({ text }) {
  const inputRef = useRef();

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        () => {
          setCopied(true);
        },
        (_error) => {
          setCopied(false);
        }
      );
    } else {
      inputRef.current.select();
      document.execCommand("copy");
      document.execCommand("copy");
      setCopied(true);
    }
  };

  return (
    <Grid container spacing={2} justify="center">
     {/*  <Grid item xs={12}>
        <hr style={{ margin: 0 }} />
      </Grid> */}
      <Grid item xs={10}>
        <TextField
          value={text}
          variant="outlined"
          margin="dense"
          fullWidth
          ref={inputRef}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                <Tooltip
                  title={copied ? "¡Copiado al portapapeles!" : "Copiar"}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => copyToClipboard()}
                  >
                    <FileCopyIcon />
                  </Button>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
