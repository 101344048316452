import React from "react";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function HeaderContent() {
  const stores = useSelector((state) => state.stores);
  return (
    <Typography variant="h5" color="textSecondary">
      {stores.dataJusto.length} sucursales
    </Typography>
  );
}
