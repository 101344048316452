import React from "react";
import { Typography, Grid } from "@material-ui/core";

export default function Contact({ data }) {
  return (
    <Grid container spacing={2} justify="flex-end">
      <Grid item xs={8}>
        <Typography variant="h6" color="textPrimary">
          Contacto cliente
        </Typography>
        <Typography variant="body2" color="textPrimary">
          Nombre: {data.firstname + " " + data.lastname}
        </Typography>
        <Typography variant="body2" color="textPrimary">
          Teléfono: {data.phone}
        </Typography>
        <Typography variant="body2" color="textPrimary">
          E-mail: {String(data.email).toLowerCase()}
        </Typography>
        <Typography variant="body2" color="textPrimary">
          Dirección: {data.address ? data.address : "-" + data.city && (", " + data.city) }
        </Typography>
      </Grid>
    </Grid>
  );
}
