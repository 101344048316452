// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Card,
  CardContent,
  makeStyles,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import HeaderPage from "../../../Frames/HeaderPage";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../redux/actions/menu.actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Index() {
  const classes = useStyles();
  const location = useLocation();

  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    image: "",
    description: "",
  });

  const init = useCallback(() => {
    if (location) {
      let search = location.pathname.replace("/menu/item/", "").split("/");
      dispatch(menuActions.getItem({ menu: search[0], item: search[1] }));
    }
  }, [dispatch, location]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (menu.viewItem) {
      setFormData(menu.viewItem);
    }
  }, [menu.viewItem]);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <HeaderPage content={<div />} />
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
              <Typography variant="h5">{formData.name}</Typography>
              <hr />
            </Grid>
            <Grid item xs={4}>
              <img
                src={formData.image}
                style={{ width: "100%" }}
                alt="imagen del producto"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                variant="outlined"
                margin="dense"
                multiline
                rows="6"
                fullWidth
                label="Descripción del producto (opcional)"
              />
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
}
