/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Paper,
  makeStyles,
  Grid,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import SecurityIcon from "@material-ui/icons/Security";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockIcon from "@material-ui/icons/Lock";
import { useSelector, useDispatch } from "react-redux";
import authActions from "../../redux/actions/auth.actions";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(400 + spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    borderRadius: "6px",
  },
  paper: {
    marginTop: spacing(18),
    marginBottom: spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
    borderRadius: "8px",
  },
}));

export default function Login() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    if (auth.loginStatus === "ERROR") {
      setTimeout(() => dispatch(authActions.loginStatus("READY")), 1500);
    }
  }, [auth.loginStatus]);

  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      dispatch(authActions.login({ ...formData }));
    }
  };

  return (
    <div
      style={{
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
      }}
      onKeyPress={handleEnterPress}
    >
      <main className={styles.main}>
        <Paper className={styles.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <img
                src={process.env.REACT_APP_LOGO_WEB}
                alt="logo"
                style={{ width: "30%" }}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Divider light />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.username}
                error={auth.loginStatus === "ERROR"}
                name="username"
                label="Usuario"
                autoComplete="username"
                autoFocus
                variant="outlined"
                fullWidth
                margin="dense"
                onChange={updateData}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <AccountBoxIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.password}
                error={auth.loginStatus === "ERROR"}
                name="password"
                type="password"
                label="Contraseña"
                variant="outlined"
                autoComplete="current-password"
                onChange={updateData}
                margin="dense"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant={
                  auth.loginStatus === "READY" ? "contained" : "outlined"
                }
                onClick={() => dispatch(authActions.login({ ...formData }))}
                startIcon={<ExitToAppIcon />}
              >
                Entrar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                fullWidth
                size="small"
                startIcon={<SecurityIcon />}
                disabled
              >
                recuperar contraseña
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </main>
    </div>
  );
}
