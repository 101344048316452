const days = {
  0: {
    text: "Domingo",
  },
  1: {
    text: "Lunes",
  },
  2: {
    text: "Martes",
  },
  3: {
    text: "Miércoles",
  },
  4: {
    text: "Jueves",
  },
  5: {
    text: "Viernes",
  },
  6: {
    text: "Sábado",
  },
};

export default function translateSched(data) {
  let temp = [];
  data.forEach((schedule) => {
    let daysTemp = "";
    schedule.days.forEach((day) => {
      daysTemp += days[day].text + ", ";
    });
    daysTemp =
      daysTemp.substr(0, daysTemp.length - 2) +
      " de " +
      schedule.hours[0].replace(".", ":") +
      " a " +
      schedule.hours[1].replace(".", ":");

    temp.push(daysTemp);
  });
  return temp;
}
