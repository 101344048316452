export const ROUTES = {
  home: {
    text: "Home",
    id: "home"
  },
  ordenes: {
    text: "Órdenes",
    id: "orders"
  },
  sucursales: {
    text: "Sucursales",
    id: "stores"
  },
  justo: {
    text: "Justo",
    id: "justo"
  },
  estado: {
    text: "Estados",
    id: "states"
  },
  ecommerce: {
    text: "Ecommerce",
    id: "ecommerce"
  },
  agregadores: {
    text: "Agregadores",
    id: "agregadores"
  },
}

export const ECOMMERCE_ROUTE = "/ecommerce/"
export const AGREGADORES_ROUTE = "/agregadores/"

