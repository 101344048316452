import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import PropTypes from "prop-types";

const style = { fontSize: 24, marginBottom: 5 };

export default function Header({ handleClose, title }) {
  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h6">
          <VisibilityIcon style={style} /> {title}
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={() => handleClose()} color="primary" variant="text">
          <CloseIcon />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
