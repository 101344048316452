import { createMuiTheme } from "@material-ui/core/styles";

// const primary = "#E8E8E8";
const secondary = "#AEB6BF";
const success = "#00C851";
const warning = "#FF8000";
const info = "#0080B3";
const errorSoft = "#FF6565";
const infoSoft = "#43A2F7";
const warningSoft = "#FFCC99";

export default createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: success,
    },
    secondary: {
      main: secondary,
    },
    success: {
      main: success,
    },
    warning: {
      main: warning,
    },
    info: {
      main: info,
    },
    errorSoft: {
      main: errorSoft,
    },
    infoSoft: {
      main: infoSoft,
    },
    warningSoft: {
      main: warningSoft
    }
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 450,
    fontWeightBold: 500,
  },

  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: "#303030",
      },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: secondary,
          color: "#fff",
        },
        "&$selected": {
          background: success,
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 3,
      },
    },
    MuiPaper: {
      root: {
        marginBottom: "4%",
      },
    },
  },
});
