import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  DialogActions,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../../../redux/actions/menu.actions";

export default function ModalConfirm({ formData }) {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);

  const [data, setData] = useState({ name: "", menusData: [] });

  useEffect(() => {
    if (formData) {
      setData({ ...formData });
    }
  }, [formData]);

  const handleCancel = () => {
    dispatch(menuActions.setCategoriesCreateModal(false));
  };

  const handleCreate = () => {
    dispatch(menuActions.setCategoriesCreateModal(false));
    dispatch(menuActions.setCategoriesView("VIEW"));
    dispatch(menuActions.createCategory(formData));
    //funcion de creación
  };

  return (
    <Dialog open={menu.categories.createModal}>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Crear categoría {data.name}</Typography>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              ¿Estás seguro de que deseas crear esta categoría?
            </Typography>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Menus:</Typography>
            {data.menusData.map((menu, key) => (
              <Typography key={"menu_" + key} variant="body1">
                {menu.name}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" color="primary">
          <Button
            variant="outlined"
            onClick={() => handleCancel()}
            startIcon={<Close />}
          >
            No, salir
          </Button>
          <Button
            variant="contained"
            onClick={() => handleCreate()}
            startIcon={<Check />}
          >
            Sí, crear
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
