// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { useEffect, useCallback } from "react";
import { Paper, Card, CardContent, makeStyles } from "@material-ui/core";
import HeaderPage from "../../Frames/HeaderPage";
import HeaderContent from "./HeaderContent";
import Selector from "./Selector";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../redux/actions/menu.actions";
import { useLocation } from "react-router-dom";
import Resume from "./Pages/Resume";
import Menus from "./Pages/Menus";
import Categories from "./Pages/Categories";
import Articles from "./Pages/Articles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const location = useLocation();

  const selectView = useCallback(() => {
    if (location) {
      let paths = location.pathname.split("/");
      if (paths.length >= 3) {
        if (paths[2] === "menus") {
          dispatch(menuActions.setPage("MENU"));
        }
        if (paths[2] === "categories") {
          dispatch(menuActions.setPage("CATEGORIES"));
        }
        if (paths[2] === "articles") {
          dispatch(menuActions.setPage("ARTICLES"));
        }
        if (paths[2] === "modifiers") {
          dispatch(menuActions.setPage("MODIFIERS"));
        }
      }
    }
  }, [dispatch, location]);

  useEffect(() => {
    selectView();
  }, [selectView]);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <HeaderPage content={<HeaderContent />} />
          <Selector />
          {menu.page === "RESUME" && <Resume />}
          {menu.page === "MENU" && <Menus />}
          {menu.page === "CATEGORIES" && <Categories />}
          {menu.page === "ARTICLES" && <Articles />}
          {menu.page === "MODIFIERS" && <div />}
        </CardContent>
      </Card>
    </Paper>
  );
}
