import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Snackbar, Slide } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import notifiesActions from "../../redux/actions/notifies.actions";
import PropTypes from "prop-types";

import AudioComponent from "./AudioComponent";
import AlertComponent from "./AlertComponent";

export default function Index() {
  const dispatch = useDispatch();
  const notifies = useSelector((state) => state.notifies).push;
  const [notification, setNotification] = useState({
    open: false,
  });

  const dequeueNotify = useCallback(() => {
    dispatch(notifiesActions.dequeueNotify());
  }, [dispatch]);

  useEffect(() => {
    if (notifies.length) {
      setNotification({
        open: true,
        ...notifies[0],
      });

      setTimeout(() => {
        setNotification((n) => {
          return {
            ...n,
            open: false,
          };
        });
      }, notifies[0].duration);

      dequeueNotify();
    }
  }, [notifies, dequeueNotify]);

  return (
    <Fragment>
      {notification.type === "snackbar" && (
        <Slide direction="up" in={notification.open}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={true}
            onClose={null}
          >
            <AlertComponent {...notification} />
          </Snackbar>
        </Slide>
      )}
      <AudioComponent {...notification} />
    </Fragment>
  );
}

AlertComponent.propTypes = {
  severity: PropTypes.string,
  text: PropTypes.string,
};
