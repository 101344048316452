import React from "react";
import { Menu as MenuIcon } from "@material-ui/icons";
import {
  Grid,
  Menu,
  AppBar,
  Toolbar,
  LinearProgress,
  IconButton,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import ProfileCard from "../ProfileCard";
import { useSelector, useDispatch } from "react-redux";
import appActions from "../../../redux/actions/app.actions";
import clsx from "clsx";
import {
  SocketStatus,
  SwitchTheme,
  SwitchProfile,
} from "./MenuItems";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

export default function MenuAppBar() {
  const classes = useStyles();
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const switchMenu = (open) => dispatch(appActions.switchMenu(open));

  return (
    <AppBar
      position="fixed"
      color="inherit"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: app.isOpenMenu,
      })}
    >
      <Toolbar>
        {auth.loged === "LOGED" && (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: app.isOpenMenu,
                })}
                onClick={() => switchMenu(true)}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <SocketStatus />
              <SwitchTheme />
              <SwitchProfile />
              <Menu
                id="menu-appbar"
                anchorEl={app.menuAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(app.menuAnchorEl)}
                onClose={() => dispatch(appActions.setMenuAnchorEl(null))}
              >
                <MenuItem disableGutters disableRipple disableTouchRipple>
                  <ProfileCard />
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        )}
      </Toolbar>
      {app.loading && (
        <LinearProgress color="primary" style={{ height: 2, marginTop: -5 }} />
      )}
    </AppBar>
  );
}
