import React from "react";
import {
  Sync as ConnectIcon,
  SyncDisabled as DisconnectIcon,
  Brightness4 as LightIcon,
  Brightness7 as DarkIcon,
  AccountCircle as AccountIcon,
} from "@material-ui/icons";
import { Chip, IconButton, useTheme, Tooltip } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useSocketContext } from "../../../context/Socket";
import appActions from "../../../redux/actions/app.actions";

export function SocketStatus() {
  const theme = useTheme();
  const { status } = useSocketContext();

  return (
    <IconButton
      aria-label="conection"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      color="inherit"
      disabled
    >
      {status === "CONNECTING" ? (
        <Chip
          icon={
            <ConnectIcon
              style={{ color: theme.palette.warning.main }}
              className="connectingIcon"
            />
          }
          label="Conectando"
        />
      ) : status === "CONNECTED" ? (
        <Chip
          icon={<ConnectIcon style={{ color: theme.palette.success.main }} />}
          label="Conectado"
        />
      ) : status === "DISCONNECTED" ? (
        <Chip
          icon={<DisconnectIcon style={{ color: theme.palette.error.main }} />}
          label="Desconectado"
        />
      ) : (
        <div />
      )}
    </IconButton>
  );
}

export function SwitchTheme() {
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  return (
    <Tooltip
      title={
        app.theme === "LIGHT" ? "Cambiar a modo oscuro" : "Cambiar a modo claro"
      }
    >
      <IconButton
        aria-label="tema"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={() =>
          dispatch(
            appActions.setTheme(app.theme === "LIGHT" ? "DARK" : "LIGHT")
          )
        }
      >
        {app.theme === "LIGHT" ? <LightIcon /> : <DarkIcon />}
      </IconButton>
    </Tooltip>
  );
}

export function SwitchProfile() {
  const dispatch = useDispatch();
  return (
    <IconButton
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={(e) => dispatch(appActions.setMenuAnchorEl(e.currentTarget))}
      color="inherit"
    >
      <AccountIcon />
    </IconButton>
  );
}
