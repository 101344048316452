const defaultState = {
  query: {
    restaurant: 1,
    store: "0",
  },
  viewItem: null,
  page: "RESUME",
  resume: {
    data: [],
    expand: "",
  },
  menus: {
    data: [],
    search: "",
    view: "VIEW",
    deleteModal: false,
    createModal: false,
  },
  categories: {
    data: [],
    search: "",
    view: "VIEW",
    deleteModal: false,
    createModal: false,
  },
  articles: {
    data: [],
    search: "",
    view: "VIEW",
    deleteModal: false,
    createModal: false,
  },
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "MENU_SET_QUERY": {
      return { ...state, query: { ...state.query, ...payload } };
    }
    case "MENU_SET_RESUME_EXPAND": {
      return { ...state, resume: { ...state.resume, expand: payload } };
    }
    case "MENU_SET_VIEW_ITEM": {
      return { ...state, viewItem: payload };
    }
    case "MENU_SET_PAGE": {
      return { ...state, page: payload };
    }

    // reducers de menus
    case "MENU_MENUS_FETCH": {
      return { ...state, menus: { ...state.menus, data: payload } };
    }
    case "MENU_SET_MENUS_SEARCH": {
      return { ...state, menus: { ...state.menus, search: payload } };
    }
    case "MENU_SET_MENUS_VIEW": {
      return { ...state, menus: { ...state.menus, view: payload } };
    }
    case "MENU_SET_MENUS_DELETE_MODAL": {
      return { ...state, menus: { ...state.menus, deleteModal: payload } };
    }
    case "MENU_SET_MENUS_CREATE_MODAL": {
      return { ...state, menus: { ...state.menus, createModal: payload } };
    }

    // reducers de categorias
    case "MENU_CATEGORIES_FETCH": {
      return { ...state, categories: { ...state.categories, data: payload } };
    }
    case "MENU_SET_CATEGORIES_SEARCH": {
      return { ...state, categories: { ...state.categories, search: payload } };
    }
    case "MENU_SET_CATEGORIES_VIEW": {
      return { ...state, categories: { ...state.categories, view: payload } };
    }
    case "MENU_SET_CATEGORIES_DELETE_MODAL": {
      return {
        ...state,
        categories: { ...state.categories, deleteModal: payload },
      };
    }
    case "MENU_SET_CATEGORIES_CREATE_MODAL": {
      return {
        ...state,
        categories: { ...state.categories, createModal: payload },
      };
    }
    // reducers de articulos
    case "MENU_ARTICLES_FETCH": {
      return { ...state, articles: { ...state.articles, data: payload } };
    }
    case "MENU_SET_ARTICLES_SEARCH": {
      return { ...state, articles: { ...state.articles, search: payload } };
    }
    case "MENU_SET_ARTICLES_VIEW": {
      return { ...state, articles: { ...state.articles, view: payload } };
    }
    case "MENU_SET_ARTICLES_DELETE_MODAL": {
      return {
        ...state,
        articles: { ...state.articles, deleteModal: payload },
      };
    }
    case "MENU_SET_ARTICLES_CREATE_MODAL": {
      return {
        ...state,
        articles: { ...state.articles, createModal: payload },
      };
    }
    default:
      return state;
  }
}

export default reducer;
