import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import appActions from "../../redux/actions/app.actions";
import {
	RoomService as OrdersIcon,
	Store as StoreIcon,
	Home as HomeIcon,
	SportsMotorsports as SportsMotorsportsIcon,
  Dvr as DvrIcon,
} from "@material-ui/icons";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import { Badge } from "@material-ui/core";
import { ROUTES, ECOMMERCE_ROUTE, AGREGADORES_ROUTE } from "../../constants/links";
const drawerWidth = 240;
const URL_STATUS=process.env.REACT_APP_API_URL_ORDENES

const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

const items = {
	1: [
		{
			text: ROUTES.home.text,
			to: "/",
			icon: <HomeIcon style={{ fontSize: 36 }} />,
			id: ROUTES.home.id,
		},
		{
			text: ROUTES.ordenes.text,
			to: "/orders",
			icon: <OrdersIcon style={{ fontSize: 36 }} />,
			id: ROUTES.ordenes.id,
		},
		{
			text: ROUTES.sucursales.text,
			to: "/stores",
			icon: <StoreIcon style={{ fontSize: 36 }} />,
			id: ROUTES.sucursales.id,
		},
		{
			text: ROUTES.justo.text,
			to: "/stores-justo",
			icon: <SportsMotorsportsIcon style={{ fontSize: 36 }} />,
			id: ROUTES.justo.id,
		},
		{
			text: ROUTES.estado.text,
			icon: <StoreIcon style={{ fontSize: 36 }} />,
			id: ROUTES.estado.id,
		},
	],
	2: [
		{
			text: ROUTES.home.text,
			to: "/",
			icon: <HomeIcon style={{ fontSize: 36 }} />,
			id: ROUTES.home.id,
		},
		{
			text: ROUTES.ordenes.text,
			to: "/orders",
			icon: <OrdersIcon style={{ fontSize: 36 }} />,
			id: ROUTES.ordenes.id,
		},
		{
			text: ROUTES.estado.text,
			icon: <StoreIcon style={{ fontSize: 36 }} />,
			id: ROUTES.estado.id,
		},
	],
	0: [],
};

export default function MiniDrawer() {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);
	const auth = useSelector((state) => state.auth);
	const notifies = useSelector((state) => state.notifies.badge);
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const [isDesktop, setIsDesktop] = useState(true);
	const storeId = auth.storeData.melt_store;
	const code = window.localStorage.getItem("token").split(" ")[1]

	const states = [
		{
			text: ROUTES.ecommerce.text,
			to: URL_STATUS + ECOMMERCE_ROUTE + storeId + `?code=${code}`,
			id: ROUTES.ecommerce.id,
			icon: <ShoppingCartIcon style={{ fontSize: 30 }}/>
		},
		{
			text: ROUTES.agregadores.text,
			to: URL_STATUS + AGREGADORES_ROUTE + storeId + `?code=${code}`,
			id: ROUTES.agregadores.id,
			icon: <MotorcycleIcon style={{ fontSize: 30 }} />
		},
	];

	const selected = (path) => {
		let currentPath = location.pathname.split("/");
		return Boolean(currentPath[1] === path.replace("/", ""));
	};

	const handleClick = () => {
		setOpen(!open);
	};

  useEffect(() => {
    const isTVOrMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|SmartTV|SMART-TV|SmartTv|Silk|Kindle|Philips|Panasonic|Toshiba|LG|Sony|Samsung|Vizio|Hisense|Hitachi|Sharp|Haier/.test(navigator.userAgent) || (window.innerWidth <= 1024 && window.innerHeight <= 768);
		setIsDesktop(!isTVOrMobile);
	},[])

	return (
		<Drawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: app.isOpenMenu,
				[classes.drawerClose]: !app.isOpenMenu,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: app.isOpenMenu,
					[classes.drawerClose]: !app.isOpenMenu,
				}),
			}}
		>
			<div className={classes.toolbar}>
				<IconButton
					onClick={() => dispatch(appActions.switchMenu(false))}
				>
					{theme.direction === "rtl" ? (
						<ChevronRightIcon />
					) : (
						<ChevronLeftIcon />
					)}
				</IconButton>
			</div>
			<Divider light />
			<List style={{ marginTop: 20 }}>
				{Boolean(items[auth.profile]) &&
					items[auth.profile].map((element) => (
						<>
							{element.text !== ROUTES.estado.text ? (
								<NavLink
									exact={true}
									to={element.to}
									key={"menu_item_" + element.text}
									strict
									style={{
										textDecoration: "none",
									}}
								>
									<ListItem
										selected={selected(element.to)}
										style={{ paddingTop: 16 }}
									>
										<ListItemIcon>
											<Badge
												badgeContent={
													notifies[element.id]
												}
												color="error"
											>
												{element.icon}
											</Badge>
										</ListItemIcon>
										<ListItemText>
											{element.text}
										</ListItemText>
									</ListItem>
								</NavLink>
							) : (
								<>
									<ListItem button onClick={handleClick}>
										<ListItemIcon>
											<Badge color="error">
												<DvrIcon
													style={{ fontSize: 36 }}
												/>
											</Badge>
										</ListItemIcon>
										<ListItemText>
											{element.text}
										</ListItemText>
										{open ? <ExpandLess /> : <ExpandMore />}
									</ListItem>
									<Collapse in={open}>
										<List>
											{states.map((item) => (
												<a
													href={item.to}
													target={isDesktop ? "_blank" : "_self"}
													key={
														"menu_subitem_" +
														item.text
													}
													style={{
														textDecoration: "none",
													}}
												>
													<ListItem
														className={
															classes.nested
														}
													>
														<ListItemIcon>
															<Badge color="error">
																{item.icon}
															</Badge>
														</ListItemIcon>
														<ListItemText>
															{item.text}
														</ListItemText>
													</ListItem>
												</a>
											))}
										</List>
									</Collapse>
								</>
							)}
						</>
					))}
			</List>
		</Drawer>
	);
}
