import axios from "axios";

const fetchStores = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/store");
      dispatch({ type: "UTILS_SET_STORES", payload: response.data });
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchAggregators = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/orders/applications");
      dispatch({ type: "UTILS_SET_AGGREGATORS", payload: response.data });
    } catch (err) {
      console.log(err);
    }
  };
};

export default {
  fetchStores,
  fetchAggregators,
};
