import React, { Fragment, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../../redux/actions/menu.actions";

import ViewMenu from "./View";
import CreateMenu from "./Create";

export default function Index() {
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const init = useCallback(() => {
    dispatch(menuActions.setMenusView("VIEW"));
  }, [dispatch]);

  useEffect(() => init(), [init]);

  return (
    <Fragment>
      {menu.menus.view === "VIEW" && <ViewMenu />}
      {menu.menus.view === "CREATE" && <CreateMenu />}
    </Fragment>
  );
}
