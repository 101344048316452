import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import Socket from "socket.io-client";
import ordersActions from "../redux/actions/orders.actions";
// import notifiesActions from "../redux/actions/notifies.actions";
import { useSelector, useDispatch } from "react-redux";
const SocketContext = createContext();

// let arrayOrders = [];

export function SocketContextProvider(props) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("CONNECTING");
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!socket) {
      const init = async () => {
        await connect({ setSocket });
      };
      setTimeout(() => init(), 1000);
    }
  }, [socket]);

  eventListener({ socket, dispatch, auth, setStatus, setSocket });

  const value = useMemo(() => {
    return { socket, status };
  }, [socket, status]);

  return <SocketContext.Provider value={value} {...props} />;
}

export function useSocketContext() {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("No suscrito a SocketContext");
  }
  return context;
}

const eventListener = ({ socket, dispatch, auth, setStatus, setSocket }) => {
  if (socket) {
    socket.on("connect", () => {
      setStatus("CONNECTED");
    });
    socket.on("close", (_err) => {
      setSocket(null);
      setStatus("DISCONNECT");
      // setTimeout(() => connect({ setSocket }), 2000);
    });
    socket.on("disconnect", (_err) => {
      setSocket(null);
      setStatus("DISCONNECT");
      // setTimeout(() => connect({ setSocket }), 2000);
    });
    // socket.off("newOrder").on("newOrder", (message) => {
    //   if (
    //     (auth.profile === 1 || auth.store === message.store_id) &&
    //     !arrayOrders.find((id) => id === message.order_id)
    //   ) {
    //     arrayOrders.push(message.order_id);
    //     dispatch(ordersActions.addData(message));
    //     if (arrayOrders.length === 30) {
    //       arrayOrders = [];
    //     }
    //   }
    // });
    socket.off("newOrder").on("newOrder", (message) => {
      if (auth.profile === 1 || auth.store === message.store_id) {
        dispatch(ordersActions.addData(message));
      }
    });

    // socket.off("updateJustoOrder").on("updateJustoOrder", (message) => {
    //   if (auth.profile === 1 ) {
    //     dispatch(notifiesActions.enqueueNotify('ORDERS_RESEND_SUCCESS'));
    //   }
    // });
    socket.on("disconnect", (_err) => {
      setSocket(null);
      setStatus("CONNECTING");
      // setTimeout(() => connect({ setSocket }), 2000);
    });
    socket.on("reconnect", (_err) => {
      setStatus("CONNECTED");
    });
    socket.on("reconnecting", (_err) => {
      setStatus("CONNECTING");
    });
  }
};

const connect = async ({ setSocket }) => {
  try {
    let temp = await Socket(process.env.REACT_APP_API_URL, {
      transports: ["websocket"],
      path: "/socket.io",
      forceNew: true,
    });
    setSocket(temp);
  } catch (err) {}
};
