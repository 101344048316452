import React from "react";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function HeaderContent() {
  const orders = useSelector((state) => state.orders);
  return (
    <Typography variant="h5" color="textSecondary">
      {new Intl.NumberFormat("es-CL").format(parseInt(orders.totalRows))}{" "}
      órdenes
    </Typography>
  );
}
