import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import storesActions from "../../../../redux/actions/stores.actions";
import Header from "../../../Frames/HeaderModal";
import Content from "./Content";

export default function ViewError() {
  const dispatch = useDispatch();
  const stores = useSelector((state) => state.stores);

  const [data, setData] = useState({});

  useEffect(() => {
    if (stores.dataModal) {
      setData(stores.dataModal);
    }
  }, [stores.dataModal]);

  useEffect(() => {
    if (stores.logError) {
      var win = window.open("", "Error");
      win.document.body.innerHTML = JSON.stringify(stores.logError);
    }
  }, [stores.logError]);

  const handleClose = () => {
    dispatch(storesActions.setLogError(null));
    dispatch(storesActions.setViewError(false));
    dispatch(storesActions.setDataModal(null));
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      open={stores.viewError}
    >
      <DialogContent>
        <Header title={"Error en " + data.store} handleClose={handleClose} />
        <Content data={data} />
      </DialogContent>
    </Dialog>
  );
}
