import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import ordersActions from "../../../redux/actions/orders.actions";
import notifySound from "../../../resources/audio/notify.wav"
import { TIME_NOTIFICATION_INTERVAL } from "../../../constants";

export default function NewOrderModal() {
  const tempInterval = useRef();
  const orders = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const handleClose = () => {
    clearInterval(tempInterval.current);
    dispatch(ordersActions.setNewOrdersModalList([]));
    dispatch(ordersActions.setNewOrdersModal(false));
  };

  useEffect(() => {
    if (orders.newOrdersModal) {
      tempInterval.current = setInterval(() => {
        let sound = new Audio(notifySound);
        sound.play();
      }, TIME_NOTIFICATION_INTERVAL);
    }
    return () => clearInterval(tempInterval.current);
  }, [orders.newOrdersModal]);

  return (
    <Dialog 
      aria-labelledby="modal-new-orders"
      fullWidth
      maxWidth="md"
      open
    >
      <DialogTitle>Notificación</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" color="textSecondary">
          Tienes {" "}
          {orders.newOrdersModalList.length}{" "}
          {orders.newOrdersModalList.length === 1
            ? "nueva orden ingresada"
            : orders.newOrdersModalList.length > 0
            ? "nuevas órdenes ingresadas"
            : "órdenes ingresadas"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonGroup color="primary" size="small" disableElevation>
          <Button
            variant="contained"
            startIcon={<Check />}
            onClick={handleClose}
          >
            Recibido
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}