
export const deliveries = {
	justo: { id: "justo", name: "Justo" },
	pya: { id: "pya", name: "Pedidos Ya" },
};

const PAGADO = ["Pago con tarjeta - Mercado Pago","credit_card"]

const containsXL = (items, xlItems = null) => {
	if (xlItems !== null) {
		return xlItems;
	}
	return items.some((item) => item.Title.includes("XL"));
};


export const isEnableDelivery = (data, xlItems = null) => {
	if (
		(data.application_id === 3 || data.application_id === 6) &&
		data.delivery === true &&
		(data.deliveryType !== "card-upon-delivery" &&
			data.deliveryType !== "cash-upon-delivery") &&
		!containsXL(data.items, xlItems)
	) {
		return true;
	}
	if(data.application_id === 10) {
		if (
			data.delivery === true &&
			PAGADO.includes(data.deliveryType) &&
			!containsXL(data.items)
		) 
		return true;
	}
	return false;
};
