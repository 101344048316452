import imageDidi from "../../image/logoDiDi.png";

const defaultState = {
  stores: [],
  ordersStatus: [
    { title: "Todos", value: "0" },
    { title: "Procesados OK", value: "1" },
    { title: "Cancelados", value: "3" },
    { title: "No procesados", value: "2" },
  ],
  appLogos: {
    1: "https://cdn.iconscout.com/icon/free/png-256/uber-eats-1613370-1369417.png",
    2: "https://www.chatfood.io/assets/images/logo/logo.svg",
    3:  process.env.REACT_APP_LOGO_WEB,
    4: "https://iconape.com/wp-content/png_logo_vector/pedidosya-logo.png",
    5: "https://tofuu.getjusto.com/orioneat-prod/7cPQRpH4kA2sbbLBR-logo.png",
    6: "https://cdn.iconscout.com/icon/free/png-256/whatsapp-155-721985.png",
    7: "https://i.ibb.co/98fjgk3/logo-melt-negro.png",
    8: "https://dev-portal.rappi.com/api/es/images/app-logo-e2c47608.png",
    9: imageDidi,
    10:  process.env.REACT_APP_LOGO_WEB
  },
  days: {
    0: {
      text: "Domingo",
    },
    1: {
      text: "Lunes",
    },
    2: {
      text: "Martes",
    },
    3: {
      text: "Miércoles",
    },
    4: {
      text: "Jueves",
    },
    5: {
      text: "Viernes",
    },
    6: {
      text: "Sábado",
    },
  },
  applications: [],
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "UTILS_SET_STORES": {
      return { ...state, stores: payload };
    }
    case "UTILS_RESET_STATE": {
      return defaultState;
    }
    case "UTILS_SET_AGGREGATORS": {
      return { ...state, applications: payload };
    }
    default:
      return state;
  }
}

export default reducer;
