import React, { Fragment } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  ButtonGroup,
  Tooltip,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import ordersActions from "../../../../redux/actions/orders.actions";
import { Refresh as RefreshIcon, Cached as RestIcon } from "@material-ui/icons";

export default function Search() {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const utils = useSelector((state) => state.utils);

  return (
    <Fragment>
      <Grid container spacing={2} alignItems="center" justify="center">
        <Grid item xs={4} sm={3} md={2}>
          <DatePicker
            name="start"
            label="Desde"
            value={orders.query.start}
            onChange={(date) =>
              dispatch(ordersActions.setQuery({ start: new Date(date) }))
            }
            fullWidth
            inputVariant="outlined"
            margin="dense"
            maxDate={orders.query.end}
            format="DD/MM/yyyy"
          />
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          <DatePicker
            name="end"
            label="Hasta"
            value={orders.query.end}
            onChange={(date) =>
              dispatch(ordersActions.setQuery({ end: new Date(date) }))
            }
            fullWidth
            inputVariant="outlined"
            margin="dense"
            minDate={orders.query.start}
            format="DD/MM/yyyy"
          />
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          <TextField
            select
            value={orders.query.status}
            onChange={(e) =>
              dispatch(ordersActions.setQuery({ status: e.target.value }))
            }
            label="Estado"
            fullWidth
            variant="outlined"
            margin="dense"
          >
            {utils.ordersStatus.map((option) => (
              <MenuItem key={"option_" + option.title} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          <Autocomplete
            fullWidth
            options={utils.stores}
            getOptionLabel={(option) => option.title}
            getOptionSelected={(option, value) => option.value === value.value}
            disableClearable
            value={
              utils.stores.length
                ? utils.stores.find(
                    (store) => store.value === orders.query.store
                  )
                : { title: "Todas", value: "0" }
            }
            onChange={(_e, k) =>
              dispatch(ordersActions.setQuery({ store: k.value }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="Sucursal"
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          <ButtonGroup>
            <Button
              variant="outlined"
              size="small"
              style={{ height: 40, marginTop: 4 }}
              startIcon={<RefreshIcon />}
              fullWidth
              onClick={() => dispatch(ordersActions.fetch())}
            >
              recargar
            </Button>
            <Button
              variant="outlined"
              size="small"
              style={{ height: 40, marginTop: 4 }}
              fullWidth
              onClick={() => dispatch(ordersActions.resetQuery())}
            >
              <Tooltip title="Reestablecer parámetros">
                <RestIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
    </Fragment>
  );
}
