import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import ordersActions from "../../../../redux/actions/orders.actions";
import MuiAlert from "@material-ui/lab/Alert";
import CallMadeIcon from "@material-ui/icons/CallMade";

export default function Status({ data }) {
  const dispatch = useDispatch();

  return (
    <Fragment>
      {data.status_order_id === 9 ? (
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          style={{ marginBottom: 10 }}
        >
          Procesada
        </MuiAlert>
      ) : data.status_order_id === 13 ? (
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="warning"
          style={{ marginBottom: 10 }}
        >
          Cancelada
        </MuiAlert>
      ) : (
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          style={{ marginBottom: 10 }}
          action={
            <Button
              color="inherit"
              variant="outlined"
              size="small"
              startIcon={<CallMadeIcon />}
              onClick={() => dispatch(ordersActions.resend(data))}
            >
              Reintentar
            </Button>
          }
        >
          Error en la orden
        </MuiAlert>
      )}
    </Fragment>
  );
}
