import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Grid,
  TableRow,
  TableSortLabel,
  Paper,
  makeStyles,
  withStyles,
  Typography,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Pagination } from "@material-ui/lab";
import { Edit, Delete } from "@material-ui/icons";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "menus",
    numeric: false,
    disablePadding: false,
    label: "Menús",
  },
  {
    id: "articles",
    numeric: false,
    disablePadding: false,
    label: "Artículos",
  },
  {
    id: "notes",
    numeric: false,
    disablePadding: false,
    label: "Notas",
  },
  {
    id: "options",
    numeric: false,
    disablePadding: false,
    label: "Opciones",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttonInfo: {
    color: "#FFF",
    background: theme.palette.info.main,
  },
  buttonWarning: {
    color: "#FFF",
    background: theme.palette.warning.main,
  },
  buttonSuccess: {
    color: "#FFF",
    background: theme.palette.success.main,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();

  const menu = useSelector((state) => state.menu);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("errors");
  const [dense] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (menu.categories.data) {
      setRows(menu.categories.data);
    }
  }, [menu.categories.data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const emptyRows = 0;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container justify="flex-end">
          <Grid item style={{ margin: 16 }}>
            <Pagination
              count={Math.round(rows.length / 7) + 1}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={(e, p) => setPage(p)}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="Sucursales"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(7 * (page - 1), 7 * page)
                .map((row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="body2" color="textSecondary">
                        {row.menus.map((menu) => menu.name).join(", ")}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography variant="body2" color="textSecondary">
                        {row.articles.map((article) => article.name).join(", ")}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.notes}</StyledTableCell>

                    <StyledTableCell align="left">
                      <ButtonGroup
                        color="primary"
                        size="small"
                        variant="contained"
                        disabled
                      >
                        <Button>
                          <Edit />
                        </Button>
                        <Button>
                          <Delete />
                        </Button>
                      </ButtonGroup>
                    </StyledTableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
