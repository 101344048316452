import React from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  Tooltip,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import storesActions from "../../../../redux/actions/stores.actions";
import { Refresh as RefreshIcon, Cached as RestIcon } from "@material-ui/icons";

export default function Search() {
  const dispatch = useDispatch();

  return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4} sm={3} md={2}>
          <ButtonGroup fullWidth variant="outlined" size="small">
            <Button
              style={{ height: 40, marginTop: 4 }}
              onClick={() => dispatch(storesActions.fetchJusto())}
              color="primary"
            >
              <Tooltip title="Actualizar sucursales">
                <RefreshIcon />
              </Tooltip>
            </Button>
            <Button
              style={{ height: 40, marginTop: 4 }}
              onClick={() => dispatch(storesActions.fetchJusto())}
            >
              <Tooltip title="Reestablecer parámetros">
                <RestIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
  );
}
