// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { Fragment, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import menuActions from "../../../../../../redux/actions/menu.actions";
import Table from "./Table";
import Cards from "./Cards";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";

export default function Index() {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const [view, setView] = useState("TABLE");

  return (
    <Fragment>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item xs={3}>
          <TextField
            value={menu.articles.search}
            onChange={(e) =>
              dispatch(menuActions.setArticlesSearch(e.target.value))
            }
            variant="outlined"
            margin="dense"
            fullWidth
            label="Buscar"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => dispatch(menuActions.setArticlesView("CREATE"))}
          >
            Nuevo artículo
          </Button>
        </Grid>
        <Grid item xs={12} align="right">
          <ToggleButtonGroup
            orientation="horizontal"
            size="small"
            value={view}
            exclusive
            onChange={(e, value) => setView(value)}
          >
            <ToggleButton value={"TABLE"} aria-label="table">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value={"CARDS"} aria-label="cards">
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <hr />
          {view === "CARDS" ? <Cards /> : <Table />}
        </Grid>
      </Grid>
    </Fragment>
  );
}
