import React from "react";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function HeaderContent() {
  const menu = useSelector((state) => state.menu);
  return (
    <Typography variant="h5">
      {menu.page === "RESUME" && "Resumen"}
      {menu.page === "MENU" && "Menús"}
      {menu.page === "CATEGORIES" && "Categorías"}
      {menu.page === "ARTICLES" && "Artículos"}
      {menu.page === "MODIFIERS" && "Modificadores"}
    </Typography>
  );
}
