const defaultState = {
  loading: true,
  cards: {
    ordersTotal: 0,
    totalSold: 0,
    ordersLastHour: 0,
  },
  charts: {
    hourlySales: [],
    topSuppliers: [],
  },
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "DASHBOARD_SET_DATA": {
      return { ...state, ...payload };
    }
    case "DASHBOARD_SET_CARDS": {
      return { ...state, cards: { ...state.cards, ...payload } };
    }
    case "DASHBOARD_SET_CHARTS": {
      return { ...state, charts: { ...state.charts, ...payload } };
    }
    case "DASHBOARD_SET_LOADING": {
      return { ...state, loading: payload };
    }
    default:
      return state;
  }
}

export default reducer;
