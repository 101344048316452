import React, { useEffect, Fragment } from "react";
import soundSuccess from "../../resources/audio/success.wav";
import soundError from "../../resources/audio/error.wav";
import soundAddOrder from "../../resources/audio/add_order.wav";
import soundWarning from "../../resources/audio/warning.wav";

export default function AudioComponent({ severity, open }) {
  useEffect(() => {
    if (open) {
      if (severity === "success") {
        let sound = new Audio(soundSuccess);
        sound.play();
      }
      if (severity === "error") {
        let sound = new Audio(soundError);
        sound.play();
      }
      if (severity === "warning") {
        let sound = new Audio(soundWarning);
        sound.play();
      }
      if (severity === "wrongOrder") {
        let sound = new Audio(soundError);
        sound.play();
      }
      if (severity === "addOrder") {
        let sound = new Audio(soundAddOrder);
        sound.play();
      }
    }
  }, [open, severity]);

  return <Fragment />;
}
