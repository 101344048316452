import React, { useState, Fragment, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  useTheme,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Check, Cancel, Add } from "@material-ui/icons";

import Schedule from "./Schedule";
import ConfirmDelete from "./ConfirmDelete";
import ConfirmCreate from "./ConfirmCreate";

import { useDispatch } from "react-redux";
import menuActions from "../../../../../../redux/actions/menu.actions";

const initialState = {
  name: "",
  schedules: [],
};

export default function CreateMenu() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState("");

  useEffect(() => {
    if (
      error === "DAYS_ERROR" ||
      error === "SCHEDULES_EMPTY" ||
      error === "NAME_ERROR"
    ) {
      setTimeout(() => setError(""), 3000);
    }
  });

  const handleCreate = () => {
    let schedules = formData.schedules;

    if (!formData.name) {
      setError("NAME_ERROR");
      return;
    }

    if (!schedules.length) {
      setError("SCHEDULES_EMPTY");
      return;
    }

    // Comprobar que no hayan cruces de horario ni arreglos de dias vacios
    for (let i = 0; i < schedules.length; i++) {
      if (!schedules[i].days.length) {
        setError("DAYS_ERROR");
        return;
      }
      for (let j = 0; j < schedules[i].days.length; j++) {
        let day = schedules[i].days[j];
        for (let k = 0; k < schedules.length; k++) {
          if (i !== k && schedules[k].days.find((tempDay) => tempDay === day)) {
            if (
              (schedules[i].hours[0] >= schedules[k].hours[0] &&
                schedules[i].hours[0] <= schedules[k].hours[1]) ||
              (schedules[i].hours[1] >= schedules[k].hours[0] &&
                schedules[i].hours[1] <= schedules[k].hours[1])
            ) {
              setError("SCHEDULE_ERROR");
              return;
            }
          }
        }
      }
    }
    setError("");
    dispatch(menuActions.setMenusCreateModal(true));
  };

  const handleCancel = () => {
    dispatch(menuActions.setMenusView("VIEW"));
  };

  // const handleDelete = () => {
  //   dispatch(menuActions.setMenusDeleteModal(true));
  // };

  const addSchedule = () => {
    setFormData({
      ...formData,
      schedules: [
        ...formData.schedules,
        {
          days: ["1", "2", "3", "4", "5", "6"],
          hours: [9, 22],
          humanHours: ["9:00", "22:00"],
        },
      ],
    });
  };

  return (
    <Fragment>
      <ConfirmDelete />
      <ConfirmCreate formData={formData} />
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h6">Crear nuevo menú</Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={4}>
          <TextField
            name={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            variant="outlined"
            label="Nombre del menú"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={error === "NAME_ERROR"}
          />
        </Grid>
        <Grid item>
          <ButtonGroup size="small">
            <Button
              onClick={() => handleCancel()}
              startIcon={<Cancel />}
              color="primary"
              variant="outlined"
            >
              Cancelar
            </Button>
            {/* <Button
              onClick={() => handleDelete()}
              startIcon={<Delete />}
              color="primary"
              variant="outlined"
            >
              Eliminar
            </Button> */}
            <Button
              onClick={() => handleCreate()}
              startIcon={<Check />}
              color="primary"
              variant="contained"
            >
              Guardar
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            startIcon={<Add />}
            onClick={() => addSchedule()}
            style={{
              marginTop: 10,
              color:
                error === "SCHEDULES_EMPTY" ? theme.palette.error.main : "",
            }}
          >
            Agregar horario
          </Button>
        </Grid>
        {error === "SCHEDULE_ERROR" && (
          <Grid item xs={12}>
            <MuiAlert
              elevation={6}
              variant="filled"
              severity="error"
              action={
                <Button
                  color="inherit"
                  variant="outlined"
                  size="small"
                  onClick={() => setError("")}
                >
                  entendido
                </Button>
              }
            >
              No puedes tener los mismos días y horas en horarios diferentes
            </MuiAlert>
          </Grid>
        )}
        <Grid container spacing={2}>
          {Boolean(formData.schedules.length) && (
            <Grid item xs={12}>
              <hr />
            </Grid>
          )}
          {formData.schedules.map((schedule, i) => (
            <Grid item xs={12} sm={12} md={12} lg={6} key={i + "schedule"}>
              <Schedule
                {...schedule}
                formData={formData}
                setFormData={setFormData}
                i={i}
                error={error}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
}
