import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  TextField,
  Grid,
  Button,
  ButtonGroup,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Check, Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import menuActions from "../../../../../../redux/actions/menu.actions";

import ModalConfirm from "./ModalConfirm";

export default function Create() {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const [formData, setFormData] = useState({
    name: "",
    menusData: [],
    menus: [],
    notes: "",
  });

  const [error, setError] = useState("");

  const fetchMenus = useCallback(() => {
    dispatch(menuActions.fetchMenus());
  }, [dispatch]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 1500);
    }
  }, [error]);

  const handleCancel = () => {
    dispatch(menuActions.setCategoriesView("VIEW"));
  };

  const handleCreate = () => {
    if (formData.name === "") {
      setError("NAME_ERROR");
      return;
    }
    if (!formData.menusData.length) {
      setError("MENUS_ERROR");
      return;
    }
    setFormData({
      ...formData,
      menus: formData.menusData.map((menu) => menu._id),
    });
    dispatch(menuActions.setCategoriesCreateModal(true));
  };

  return (
    <Fragment>
      <ModalConfirm formData={formData} />
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h6">Crear nueva categoría</Typography>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <TextField
            name={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            variant="outlined"
            label="Nombre de la categoría"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            error={error === "NAME_ERROR"}
          />
        </Grid>
        <Grid item>
          <ButtonGroup size="small">
            <Button
              onClick={() => handleCancel()}
              startIcon={<Cancel />}
              color="primary"
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleCreate()}
              startIcon={<Check />}
              color="primary"
              variant="contained"
            >
              Guardar
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column">
        <Grid item xs={8} lg={6}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={menu.menus.data}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            fullWidth
            value={formData.menusData}
            onChange={(_e, k) => setFormData({ ...formData, menusData: k })}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Menús que contienen la categoría"
                placeholder="Menús"
                margin="dense"
                error={error === "MENUS_ERROR"}
              />
            )}
          />
        </Grid>
        <Grid item xs={8} lg={6}>
          <TextField
            value={formData.notes}
            fullWidth
            margin="dense"
            variant="outlined"
            label="Notas"
            onChange={(e) =>
              setFormData({ ...formData, notes: e.target.value })
            }
            multiline
            rows="4"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
