/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function ThisLineChart() {
  const theme = useTheme();

  const hourlySales = useSelector((state) => state.dashboard).charts
    .hourlySales;

  const [data, setData] = useState([]);

  const translate = {
    total: { text: "Total", color: theme.palette.info.main },
    IC: { text: "Ingresadas correctamente", color: theme.palette.success.main },
    NI: { text: "No ingresadas", color: theme.palette.error.main },
    C: { text: "Canceladas", color: theme.palette.warning.main },
  };

  useEffect(() => {
    if (hourlySales) {
      const date = new Date();
      setData(
        hourlySales
          .filter((hs) => parseInt(hs.name) < date.getHours() + 3)
          .map((el) => {
            return {
              name: el.name + "Hrs",
              [translate.total.text]: el.total,
              [translate.IC.text]: el.IC,
              [translate.NI.text]: el.NI,
              [translate.C.text]: el.C,
            };
          })
      );
    }
  }, [hourlySales]);

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis orientation="right" />
          <Tooltip />
          <CartesianGrid vertical={false} />
          <Legend verticalAlign="top" align="left" />
          {data.length &&
            Object.keys(translate).map((key) => {
              return (
                <Line
                  dot={false}
                  key={key}
                  type="monotone"
                  dataKey={translate[key].text}
                  stroke={translate[key].color}
                  activeDot={{ r: 8 }}
                  strokeWidth={2}
                  legendType="square"
                />
              );
            })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
