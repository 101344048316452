import React, { useState, useEffect } from "react";
import { Grid, Typography, Zoom, makeStyles } from "@material-ui/core";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import { useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  DARK: {
    height: 140,
    margin: 30,
    opacity: 0.2,
    filter: "invert(1)",
  },
  LIGHT: {
    height: 140,
    margin: 30,
    opacity: 0.2,
  },
}));

export default function Index() {
  const dashboard = useSelector((state) => state.dashboard);
  const app = useSelector((state) => state.app);
  const [loadText, setLoadText] = useState("Cargando.");
  const styles = useStyles();

  useEffect(() => {
    if (dashboard.loading) {
      const interval = setInterval(() => {
        if (loadText.length < 11) {
          setLoadText(loadText + ".");
        } else {
          setLoadText("Cargando");
        }
      }, 500);
      return () => clearInterval(interval);
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={8} align="center">
        <Typography
          variant="h6"
          align="center"
          style={{ marginBottom: 16 }}
          color="textSecondary"
        >
          {!dashboard.loading ? "VENTAS POR HORA DEL DÍA" : <Skeleton />}
        </Typography>
        {dashboard.loading ? (
          <Zoom in={true}>
            <div>
              <img
                className={styles[app.theme]}
                src="https://image.flaticon.com/icons/svg/843/843734.svg"
                alt="cargando gráfico"
              />
              <Typography variant="body2" color="textSecondary">
                {loadText}
              </Typography>
            </div>
          </Zoom>
        ) : (
          <LineChart />
        )}
      </Grid>
      <Grid item xs={4} align="center">
        <Typography
          variant="h6"
          align="center"
          style={{ marginBottom: 16 }}
          color="textSecondary"
        >
          {!dashboard.loading ? "TOP AGREGADORES DEL DÍA" : <Skeleton />}
        </Typography>
        {dashboard.loading ? (
          <Zoom in={true}>
            <div>
              <img
                src="https://image.flaticon.com/icons/svg/886/886170.svg"
                alt="cargando gráfico"
                className={styles[app.theme]}
              />
              <Typography variant="body1" color="textSecondary">
                {loadText}
              </Typography>
            </div>
          </Zoom>
        ) : (
          <PieChart />
        )}
      </Grid>
    </Grid>
  );
}
