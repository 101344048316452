const defaultState = {
  push: [],
  badge: {
    home: 0,
    orders: 0,
    stores: 0,
  },
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "NOTIFY_ENQUEUE": {
      return { ...state, push: [...state.push, payload] };
    }
    case "NOTIFY_DEQUEUE": {
      let newState = state.push;
      newState.shift();
      return { ...state, push: newState };
    }
    case "NOTIFY_ADD_BADGET": {
      return {
        ...state,
        badge: { ...state.badge, [payload]: state.badge[payload] + 1 },
      };
    }
    case "NOTIFY_RESET_BADGET": {
      return {
        ...state,
        badge: { ...state.badge, [payload]: 0 },
      };
    }
    case "NOTIFY_RESET_STATE": {
      return defaultState;
    }
    
    default:
      return state;
  }

  
}

export default reducer;
