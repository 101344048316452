import React, { Fragment } from "react";
import { Typography, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import storesActions from "../../../../redux/actions/stores.actions";
import MuiAlert from "@material-ui/lab/Alert";
import CallMadeIcon from "@material-ui/icons/CallMade";
import PropTypes from "prop-types";

export default function Content({ data }) {
  const dispatch = useDispatch();

  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      severity="error"
      action={
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          startIcon={<CallMadeIcon />}
          onClick={() => dispatch(storesActions.getError(data.storeId))}
        >
          Log
        </Button>
      }
    >
      <Typography variant="body1">
        <strong>Errores encontrados:</strong>
      </Typography>

      {data.statusValidation !== "Success" && (
        <Fragment>
          <hr />
          <Typography variant="body1">
            <strong>ValidationError:</strong> {data.statusValidation}
          </Typography>
        </Fragment>
      )}

      {data.statusInserted !== "Success" && (
        <Fragment>
          <hr />
          <Typography variant="body1">
            <strong>InsertionError:</strong> {data.statusInserted}
          </Typography>
        </Fragment>
      )}
    </MuiAlert>
  );
}

Content.propTypes = {
  data: PropTypes.object.isRequired,
};
